"use client";
import { Collapse, CollapseProps } from "antd";
import React from "react";
import { FileData } from "react-diff-view";

import { RemoteCommitsContent } from "../../data/TurnEventContent";
import ImmutableDiffCard from "../ImmutableDiffCard";

import { FileInfo, toFileInfos } from "../Utils";

import { buildCommitLabel, buildGithubLinkButton } from "./Common";

import { InlineMessage } from "./InlineMessage";
import { BranchesOutlined } from "@ant-design/icons";

import "./Common.scss";

interface RemoteCommitsProps {
    content: RemoteCommitsContent;
    fullRepoName: string;
}

const RemoteCommits: React.FC<RemoteCommitsProps> = ({ content, fullRepoName }) => {
    const buildContent = (repoName: string, content: RemoteCommitsContent): CollapseProps["items"] => {
        return content.commits.map((commit, idx) => {
            return {
                key: idx,
                label: buildCommitLabel(commit.author, commit.date, commit.message),
                extra: buildGithubLinkButton(repoName, commit.commit),
                children: toFileInfos([{ patch: commit.patch, change_ids: [] }], []).map((fileInfo: FileInfo) => {
                    const diffCardKey = (fileData: FileData) => `${fileData.oldRevision}-${fileData.newRevision}`;
                    return (
                        <ImmutableDiffCard
                            key={diffCardKey(fileInfo.fileData)}
                            fileInfo={fileInfo}
                            expandCodeFn={() => {}}
                        />
                    );
                }),
            };
        });
    };

    return (
        <div>
            <InlineMessage icon={<BranchesOutlined />} text="Synchronized with the upstream branch." />
            <Collapse
                className="commits-collapse"
                items={buildContent(fullRepoName, content)}
                defaultActiveKey={[]}
                accordion={true}
            />
        </div>
    );
};

export default RemoteCommits;
