"use client";
import React from "react";

import { Checkbox, Radio } from "antd";

import "./FilterList.scss";

export type FilterListItem = {
    key: string;
    label: string;
    iconUrl?: string;
};

const FilterList: React.FC<{
    items: FilterListItem[];
    selectedKeys?: string[];
    onSelect: (key: string) => void;
    onDeselect?: (key: string) => void;
    selectable?: boolean;
    buttonStyle: "checkbox" | "radio";
}> = ({ items, selectedKeys = [], onSelect, onDeselect, selectable = true, buttonStyle }) => {
    const buildItem = (item: FilterListItem) => {
        const isSelected = selectedKeys.includes(item.key);
        const button = buttonStyle === "checkbox" ? <Checkbox checked={isSelected} /> : <Radio checked={isSelected} />;

        return (
            <div
                onClick={() => {
                    if (isSelected) {
                        onDeselect && onDeselect(item.key);
                    } else {
                        onSelect(item.key);
                    }
                }}
                className="filter-list-item"
            >
                {selectable && button}
                {item.iconUrl && <img className="filter-list-item-icon" src={item.iconUrl} alt={item.label} />}
                <span className="filter-list-item-label">{item.label}</span>
            </div>
        );
    };

    return <div className="filter-list">{items.map(buildItem)}</div>;
};

export default FilterList;
