import { solverInterfaceApiAxios } from "./SolverInterfaceConstants";
import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

import { useSolverInterfaceContext } from "./SolverInterface";

interface DockerExecutionSettings {
    docker_registry_url?: string;
    docker_image?: string;
    docker_tag?: string;
    repo_mount_point?: string;
    docker_username?: string;
    docker_password?: string;
    shell_executable?: string;
}

type DefaultExecutionSettings = {
    image_id: string;
    title: string;
    detail: string;
};

type ExecutionEnvironmentVariable = {
    name: string;
    value: string | null;
    secret: boolean;
};

enum ExecutionImageType {
    Default,
    Custom,
    None,
}

type ConfiguredExecutionImage =
    | CustomConfiguredExecutionImage
    | DefaultConfiguredExecutionImage
    | NoneConfiguredExecutionImage;

type CustomConfiguredExecutionImage = {
    type: ExecutionImageType.Custom;
    settings: DockerExecutionSettings;
};

type DefaultConfiguredExecutionImage = {
    type: ExecutionImageType.Default;
    settings: DefaultExecutionSettings;
};

type NoneConfiguredExecutionImage = {
    type: ExecutionImageType.None;
};

export type ExecutionSettingsContextType = {
    currentExecutionImage: ConfiguredExecutionImage;
    updateCustomExecutionImage: (settings: DockerExecutionSettings) => Promise<void>;
    updateDefaultExecutionImage: (settings: DefaultExecutionSettings) => Promise<void>;
    updatingImage: boolean;
    resetExecutionImage: () => Promise<void>;
    imageError: string | undefined;
    loadingImage: boolean;
    defaultExecutionImages: DefaultExecutionSettings[];
    loadingDefaultExecutionImages: boolean;
    defaultExecutionImagesError: string | undefined;
    executionEnvironment: ExecutionEnvironmentVariable[];
    addEnvironmentVariable: (name: string, value: string | null, secret: boolean) => Promise<void>;
    deleteEnvironmentVariable: (name: string) => Promise<void>;
    resetExecutionEnvironment: () => Promise<void>;
    environmentError: string | undefined;
    loadingEnvironment: boolean;
};

const ExecutionSettingsContext = createContext<ExecutionSettingsContextType | undefined>(undefined);

const ExecutionSettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { activeRepo } = useSolverInterfaceContext();
    const [customExecutionImage, setCustomExecutionImage] = useState<DockerExecutionSettings | undefined>(undefined);
    const [defaultExecutionImage, setDefaultExecutionImage] = useState<DefaultExecutionSettings | undefined>(undefined);
    const [loadingImage, setLoadingImage] = useState<boolean>(false);
    const [imageError, setImageError] = useState<string | undefined>(undefined);

    const [defaultExecutionImages, setDefaultExecutionImages] = useState<DefaultExecutionSettings[]>([]);
    const [loadingDefaultExecutionImages, setLoadingDefaultExecutionImages] = useState<boolean>(false);
    const [defaultExecutionImagesError, setDefaultExecutionImagesError] = useState<string | undefined>(undefined);

    const [updatingImage, setUpdatingImage] = useState<boolean>(false);

    const [executionEnvironment, setExecutionEnvironment] = useState<ExecutionEnvironmentVariable[]>([]);
    const [loadingEnvironment, setLoadingEnvironment] = useState<boolean>(false);
    const [environmentError, setEnvironmentError] = useState<string | undefined>(undefined);

    useEffect(() => {
        const refreshExecutionSettings = async () => {
            if (!activeRepo) {
                setCustomExecutionImage(undefined);
                setDefaultExecutionImage(undefined);
            } else {
                setLoadingImage(true);

                const [customExecutionImageResult, defaultExecutionImageResult] = await Promise.all([
                    getCustomExecutionImage(activeRepo.org, activeRepo.name),
                    getDefaultExecutionImage(activeRepo.org, activeRepo.name),
                ]);

                setCustomExecutionImage(customExecutionImageResult.dockerExecutionSettings);
                setDefaultExecutionImage(defaultExecutionImageResult.defaultExecutionSettings);
                setImageError(
                    customExecutionImageResult.error || defaultExecutionImageResult.error
                        ? "Failed to load execution image settings"
                        : undefined
                );
                setLoadingImage(false);
            }
        };

        refreshExecutionSettings();
    }, [activeRepo]);

    useEffect(() => {
        const refreshDefaultExecutionImages = async () => {
            if (!activeRepo) {
                setDefaultExecutionImages([]);
            } else {
                setLoadingDefaultExecutionImages(true);

                const { defaultExecutionSettings, error } = await listDefaultExecutionImages(
                    activeRepo.org,
                    activeRepo.name
                );

                if (!error) {
                    setDefaultExecutionImages(defaultExecutionSettings);
                } else {
                    setDefaultExecutionImagesError("Failed to load default execution images");
                }
                setLoadingDefaultExecutionImages(false);
            }
        };

        refreshDefaultExecutionImages();
    }, [activeRepo]);

    useEffect(() => {
        const refreshExecutionEnvironment = async () => {
            if (!activeRepo) {
                return;
            }

            setLoadingEnvironment(true);
            const getEnvironmentResult = await getExecutionEnvironment(activeRepo.org, activeRepo.name);
            setExecutionEnvironment(getEnvironmentResult.environment);
            setEnvironmentError(getEnvironmentResult.error ? "Failed to load environment variables" : undefined);
            setLoadingEnvironment(false);
        };

        refreshExecutionEnvironment();
    }, [activeRepo]);

    const updateCustomExecutionImage = async (newDockerExecutionSettings: DockerExecutionSettings) => {
        if (!activeRepo) {
            console.warn("No active repo");
            return Promise.resolve();
        }

        setUpdatingImage(true);
        const result = await postCustomExecutionImage(activeRepo.org, activeRepo.name, newDockerExecutionSettings);

        if (result.success) {
            setCustomExecutionImage((prevDockerExecutionSettings) => {
                return { ...prevDockerExecutionSettings, ...newDockerExecutionSettings };
            });
            setImageError(undefined);
        } else {
            setImageError(result.message);
        }
        setUpdatingImage(false);
    };

    const updateDefaultExecutionImage = async (newDefaultExecutionSettings: DefaultExecutionSettings) => {
        if (!activeRepo) {
            console.warn("No active repo");
            return Promise.resolve();
        }

        setUpdatingImage(true);
        const result = await postDefaultExecutionImage(
            activeRepo.org,
            activeRepo.name,
            newDefaultExecutionSettings.image_id
        );

        if (result.success) {
            setDefaultExecutionImage(newDefaultExecutionSettings);
            setImageError(undefined);
        } else {
            setImageError(result.message);
        }
        setUpdatingImage(false);
    };

    const resetExecutionImage = async () => {
        if (!activeRepo) {
            console.warn("No active repo");
            return Promise.resolve();
        }

        if (await deleteExecutionImage(activeRepo.org, activeRepo.name)) {
            setCustomExecutionImage(undefined);
            setDefaultExecutionImage(undefined);
            setImageError(undefined);
        } else {
            setImageError("Failed to delete image setting");
        }
    };

    const addEnvironmentVariable = async (name: string, value: string | null, secret: boolean) => {
        if (!activeRepo) {
            console.warn("No active repo");
            return Promise.resolve();
        }

        const success = await updateExecutionEnvironment(
            activeRepo.org,
            activeRepo.name,
            [{ name, value, secret }],
            []
        );
        if (success) {
            setExecutionEnvironment((prev) => {
                return [...prev.filter((variable) => variable.name !== name), { name, value, secret }];
            });
            setEnvironmentError(undefined);
        } else {
            setEnvironmentError("Failed to add environment variable");
        }
    };

    const deleteEnvironmentVariable = async (name: string) => {
        if (!activeRepo) {
            console.warn("No active repo");
            return Promise.resolve();
        }

        const success = await updateExecutionEnvironment(activeRepo.org, activeRepo.name, [], [name]);
        if (success) {
            setExecutionEnvironment((prev) => prev.filter((variable) => variable.name !== name));
            setEnvironmentError(undefined);
        } else {
            setEnvironmentError("Failed to delete environment variable");
        }
    };

    const resetExecutionEnvironment = async () => {
        if (!activeRepo) {
            console.warn("No active repo");
            return Promise.resolve();
        }

        if (await deleteExecutionEnvironment(activeRepo.org, activeRepo.name)) {
            setExecutionEnvironment([]);
            setEnvironmentError(undefined);
        } else {
            setEnvironmentError("Failed to reset environment variables");
        }
    };

    const configuredExecutionImage = () => {
        if (customExecutionImage) {
            return {
                type: ExecutionImageType.Custom,
                settings: customExecutionImage,
            } as CustomConfiguredExecutionImage;
        } else if (defaultExecutionImage) {
            return {
                type: ExecutionImageType.Default,
                settings: defaultExecutionImage,
            } as DefaultConfiguredExecutionImage;
        } else {
            return {
                type: ExecutionImageType.None,
            } as NoneConfiguredExecutionImage;
        }
    };

    return (
        <ExecutionSettingsContext.Provider
            value={{
                currentExecutionImage: configuredExecutionImage(),
                updateCustomExecutionImage,
                updateDefaultExecutionImage,
                updatingImage,
                resetExecutionImage,
                imageError,
                loadingImage,
                defaultExecutionImages,
                loadingDefaultExecutionImages,
                defaultExecutionImagesError,
                executionEnvironment,
                addEnvironmentVariable,
                deleteEnvironmentVariable,
                resetExecutionEnvironment,
                environmentError,
                loadingEnvironment,
            }}
        >
            {children}
        </ExecutionSettingsContext.Provider>
    );
};

const useExecutionSettingsContext = () => {
    const ctx = React.useContext(ExecutionSettingsContext);
    if (!ctx) {
        throw new Error("useExecutionSettingsContext must be used within a ExecutionSettingsContextProvider");
    }
    return ctx;
};

type GetCustomExecutionImageResult = {
    dockerExecutionSettings?: DockerExecutionSettings;
    error: boolean;
};

const getCustomExecutionImage = async (org: string, repo: string): Promise<GetCustomExecutionImageResult> => {
    return await solverInterfaceApiAxios
        .get<DockerExecutionSettings>(`${org}/${repo}/execution/settings/custom`)
        .then((response) => {
            if (response.status === 204) {
                return { dockerExecutionSettings: undefined, error: false };
            }

            return { dockerExecutionSettings: response.data, error: false };
        })
        .catch((error) => {
            console.log(error);
            return { dockerExecutionSettings: undefined, error: true };
        });
};

const postCustomExecutionImage = async (
    org: string,
    repo: string,
    dockerExecutionSettings: DockerExecutionSettings
): Promise<{ success: boolean; message?: string }> => {
    try {
        await solverInterfaceApiAxios.post(`${org}/${repo}/execution/settings/custom`, dockerExecutionSettings);
        return { success: true };
    } catch (error) {
        console.log(error);
        if (axios.isAxiosError(error) && error.response?.data?.message) {
            return {
                success: false,
                message: error.response.data.message,
            };
        }
        return {
            success: false,
            message: "Failed to save execution settings",
        };
    }
};

type ListDefaultExecutionImagesResult = {
    defaultExecutionSettings: DefaultExecutionSettings[];
    error: boolean;
};

const listDefaultExecutionImages = async (org: string, repo: string): Promise<ListDefaultExecutionImagesResult> => {
    return await solverInterfaceApiAxios
        .get<DefaultExecutionSettings[] | undefined>(`${org}/${repo}/execution/settings/default/list`)
        .then((response) => {
            return { defaultExecutionSettings: response.data || [], error: false };
        })
        .catch((error) => {
            console.log(error);
            return { defaultExecutionSettings: [], error: true };
        });
};

type GetDefaultExecutionImageResult = {
    defaultExecutionSettings?: DefaultExecutionSettings;
    error: boolean;
};

const getDefaultExecutionImage = async (org: string, repo: string): Promise<GetDefaultExecutionImageResult> => {
    return await solverInterfaceApiAxios
        .get<DefaultExecutionSettings>(`${org}/${repo}/execution/settings/default`)
        .then((response) => {
            if (response.status === 204) {
                return { defaultExecutionSettings: undefined, error: false };
            }

            return { defaultExecutionSettings: response.data, error: false };
        })
        .catch((error) => {
            console.log(error);
            return { defaultExecutionSettings: undefined, error: true };
        });
};

const postDefaultExecutionImage = async (
    org: string,
    repo: string,
    image_id: string
): Promise<{ success: boolean; message?: string }> => {
    try {
        console.log(image_id);
        await solverInterfaceApiAxios.post(`${org}/${repo}/execution/settings/default`, { image_id });
        return { success: true };
    } catch (error) {
        console.log(error);
        if (axios.isAxiosError(error) && error.response?.data?.message) {
            return {
                success: false,
                message: error.response.data.message,
            };
        }
        return {
            success: false,
            message: "Failed to save execution settings",
        };
    }
};

const deleteExecutionImage = async (org: string, repo: string): Promise<boolean> => {
    try {
        await solverInterfaceApiAxios.delete(`${org}/${repo}/execution/settings`);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};

type GetExecutionEnvironmentResult = {
    environment: ExecutionEnvironmentVariable[];
    error: boolean;
};

const getExecutionEnvironment = async (org: string, repo: string): Promise<GetExecutionEnvironmentResult> => {
    return await solverInterfaceApiAxios
        .get<ExecutionEnvironmentVariable[]>(`${org}/${repo}/execution/environment`)
        .then((response) => {
            if (response.status === 204) {
                return { environment: [], error: false };
            }

            return { environment: response.data, error: false };
        })
        .catch((error) => {
            console.log(error);
            return { environment: [], error: true };
        });
};

const updateExecutionEnvironment = async (
    org: string,
    repo: string,
    variablesToSet: ExecutionEnvironmentVariable[],
    variablesToDelete: string[]
): Promise<boolean> => {
    const variables = [...variablesToSet, ...variablesToDelete.map((name) => ({ name, value: null, secret: false }))];

    try {
        await solverInterfaceApiAxios.post(`${org}/${repo}/execution/environment`, variables);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const deleteExecutionEnvironment = async (org: string, repo: string): Promise<boolean> => {
    try {
        await solverInterfaceApiAxios.delete(`${org}/${repo}/execution/environment`);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export { ExecutionImageType, ExecutionSettingsProvider, useExecutionSettingsContext };

export type { DockerExecutionSettings, ExecutionEnvironmentVariable };
