"use client";
import {
    ArrowRightOutlined,
    EditFilled,
    FileOutlined,
    FileZipOutlined,
    FolderOutlined,
    MinusCircleFilled,
    PlusCircleFilled,
    SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Tree } from "antd";
import React, { useLayoutEffect, useMemo, useState } from "react";
import { FileInfo, getRelevantPath, fileWasRenamed } from "./Utils";

import styleConstants from "../constants.module.scss";
import "./ChangedFileTree.scss";

type FileType = "add" | "delete" | "modify" | "rename";

interface ChangedFileTreeProps {
    files: FileInfo[];
    onFileSelect: (filePath: string) => void;
    selectedFile: string | null;
    onDownloadSelectedFiles: (paths: string[]) => void;
}

interface ChangedFileTreeNode {
    title: string;
    key: string;
    icon?: React.ReactNode;
    children?: ChangedFileTreeNode[];
    isFile: boolean;
}

const getChangeIcon = (changeType: FileType) => {
    switch (changeType) {
        case "add":
            return <PlusCircleFilled style={{ color: "#4caf50" }} />;
        case "delete":
            return <MinusCircleFilled style={{ color: "#f44336" }} />;
        case "modify":
            return <EditFilled style={{ color: styleConstants.SOLVER_BLUE }} />;
        case "rename":
            return <ArrowRightOutlined style={{ color: styleConstants.SOLVER_BLUE }} />;
        default:
            return <EditFilled style={{ color: styleConstants.SOLVER_BLUE }} />;
    }
};

const buildTreeData = (files: FileInfo[]): ChangedFileTreeNode[] => {
    const tree: Record<string, ChangedFileTreeNode> = {};
    files.forEach((file) => {
        const filePath = getRelevantPath(file.fileData);
        const parts = filePath.split("/");
        let currentLevel = tree;
        parts.forEach((part, index) => {
            if (!currentLevel[part]) {
                const fileType = fileWasRenamed(file.fileData.type, file.fileData.similarity)
                    ? "rename"
                    : (file.fileData.type as FileType);
                currentLevel[part] =
                    index === parts.length - 1
                        ? {
                              title: part,
                              key: filePath,
                              icon: getChangeIcon(fileType),
                              isFile: true,
                          }
                        : ({
                              title: part,
                              key: parts.slice(0, index + 1).join("/"),
                              children: {},
                              isFile: false,
                          } as ChangedFileTreeNode);
            }
            currentLevel = (currentLevel[part].children || {}) as Record<string, ChangedFileTreeNode>;
        });
    });

    const compressTree = (node: Record<string, ChangedFileTreeNode>): ChangedFileTreeNode[] => {
        return Object.entries(node).map(([, currentNode]) => {
            if (currentNode.isFile) {
                return currentNode;
            } else {
                const children = compressTree((currentNode.children || {}) as Record<string, ChangedFileTreeNode>);
                if (children.length === 1 && !children[0].isFile) {
                    // Compress single child directory
                    return {
                        ...children[0],
                        title: `${currentNode.title}/${children[0].title}`,
                        key: children[0].key,
                    };
                } else {
                    return {
                        ...currentNode,
                        icon: <FolderOutlined style={{ color: styleConstants.SOLVER_BLUE }} />,
                        children,
                    };
                }
            }
        });
    };

    return compressTree(tree);
};

const getAllKeys = (nodes: ChangedFileTreeNode[]): React.Key[] => {
    let keys: React.Key[] = [];
    nodes.forEach((node) => {
        keys.push(node.key);
        if (node.children) {
            keys = keys.concat(getAllKeys(node.children));
        }
    });
    return keys;
};

const getFilePaths = (nodes: ChangedFileTreeNode[]): string[] => {
    let paths: string[] = [];
    nodes.forEach((node) => {
        if (node.isFile) {
            paths.push(node.key);
        }
        if (node.children) {
            paths = paths.concat(getFilePaths(node.children));
        }
    });
    return paths;
};
const ChangedFileTree: React.FC<ChangedFileTreeProps> = ({
    files,
    onFileSelect,
    selectedFile,
    onDownloadSelectedFiles,
}) => {
    const [searchValue, setSearchValue] = useState("");
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

    const [checkedPaths, setCheckedPaths] = useState<string[]>([]);
    const allFilePaths = useMemo(() => getFilePaths(buildTreeData(files)), [files]);
    useLayoutEffect(() => {
        if (files.length > 0) {
            const allKeys = getAllKeys(buildTreeData(files));
            setExpandedKeys(allKeys);
        }
    }, [files]);

    const treeData = useMemo(() => {
        const filterNode = (node: ChangedFileTreeNode): ChangedFileTreeNode | null => {
            if (node.key.toLowerCase().includes(searchValue.toLowerCase())) {
                return node;
            }
            if (node.children && node.children.length > 0) {
                const filteredChildren = node.children
                    .map(filterNode)
                    .filter((n): n is ChangedFileTreeNode => n !== null);
                if (filteredChildren.length > 0) {
                    return { ...node, children: filteredChildren };
                }
            }
            return null;
        };

        if (searchValue === "") {
            return buildTreeData(files);
        } else {
            return buildTreeData(files)
                .map(filterNode)
                .filter((n): n is ChangedFileTreeNode => n !== null);
        }
    }, [files, searchValue]);

    return (
        <>
            <div className="changed-file-tree-search">
                <Input
                    placeholder="Search files"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    addonBefore={<SearchOutlined />}
                />
            </div>
            <Button
                className="changed-file-tree-download-zip-button"
                size="small"
                icon={<FileZipOutlined />}
                disabled={files.length === 0}
                onClick={() => onDownloadSelectedFiles(checkedPaths.length === 0 ? allFilePaths : checkedPaths)}
            >
                Download {checkedPaths.length || allFilePaths.length} file
                {checkedPaths.length === 1 || (checkedPaths.length === 0 && allFilePaths.length === 1) ? "" : "s"}
            </Button>
            <Tree<ChangedFileTreeNode>
                className="changed-file-tree"
                treeData={treeData}
                selectedKeys={selectedFile ? [selectedFile] : []}
                expandedKeys={expandedKeys}
                defaultExpandAll={true}
                onExpand={(expanded) => setExpandedKeys(expanded)}
                expandAction="click"
                checkable={true}
                onSelect={(_, info) => {
                    const node = info.node as ChangedFileTreeNode;
                    if (node.isFile) {
                        onFileSelect(node.key);
                    }
                }}
                onCheck={(checkedData, info) => {
                    const checkedNodes = info.checkedNodes as ChangedFileTreeNode[];
                    const checkedFiles = checkedNodes.filter((node) => node.isFile).map((node) => node.key);
                    setCheckedPaths(checkedFiles);
                }}
                titleRender={(nodeData: ChangedFileTreeNode) => (
                    <span className="changed-file-tree-node-title">
                        {nodeData.icon || <FileOutlined />}
                        <span className="changed-file-tree-node-text">{nodeData.title}</span>
                    </span>
                )}
            />
        </>
    );
};

export default ChangedFileTree;
