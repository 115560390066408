"use client";
import React from "react";

import { parseDiff } from "react-diff-view";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { getRelevantPath } from "./Utils";

import ImmutableDiffCard from "./ImmutableDiffCard";

import "./SolverMarkdown.scss";

const SolverMarkdown: React.FC<{ text: string; linkInNewTab?: boolean }> = ({ text, linkInNewTab = true }) => {
    const buildCodeTag = ({
        className,
        children,
        ...props
    }: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) => {
        return (
            <code className={`${className ? className : ""} markdown-code`} {...props}>
                {children}
            </code>
        );
    };

    return (
        <Markdown
            className="markdown"
            remarkPlugins={[remarkGfm]}
            components={{
                h1: ({ className, children, ...props }) => {
                    return (
                        <h1 className={`${className ? className : ""} markdown-h1`} {...props}>
                            {children}
                        </h1>
                    );
                },
                p: ({ className, children, ...props }) => {
                    return (
                        <p className={`${className ? className : ""} markdown-paragraph`} {...props}>
                            {children}
                        </p>
                    );
                },
                code: ({ className, children, ...props }) => {
                    const preHasDiff = !!className && /language-diff/.test(className);

                    if (preHasDiff) {
                        let diff;
                        try {
                            diff = parseDiff(children as string);
                        } catch (e) {
                            // If the diff is invalid, fall back to the default code tag.
                            console.error("Error parsing diff:", e);
                            return buildCodeTag({ className, children, ...props });
                        }

                        return (
                            <>
                                {diff.map((file) => {
                                    const fileInfo = {
                                        fileData: file,
                                        source: null,
                                        fileLines: 0,
                                        change_ids: [],
                                        rawPatch: null,
                                    };

                                    return (
                                        <ImmutableDiffCard
                                            className="markdown-diff-card"
                                            key={getRelevantPath(file)}
                                            fileInfo={fileInfo}
                                        />
                                    );
                                })}
                            </>
                        );
                    }

                    return buildCodeTag({ className, children, ...props });
                },
                pre: ({ className, children, ...props }) => {
                    return (
                        <pre className={`${className ? className : ""} markdown-pre`} {...props}>
                            {children}
                        </pre>
                    );
                },
                img: ({ className, src, alt, ...props }) => {
                    return (
                        <img className={`${className ? className : ""} markdown-img`} src={src} alt={alt} {...props} />
                    );
                },
                table: ({ className, children, ...props }) => {
                    return (
                        <table className={`${className ? className : ""} markdown-table`} {...props}>
                            {children}
                        </table>
                    );
                },
                thead: ({ className, children, ...props }) => {
                    return (
                        <thead className={`${className ? className : ""} markdown-thead`} {...props}>
                            {children}
                        </thead>
                    );
                },
                tbody: ({ className, children, ...props }) => {
                    return (
                        <tbody className={`${className ? className : ""} markdown-tbody`} {...props}>
                            {children}
                        </tbody>
                    );
                },
                tr: ({ className, children, ...props }) => {
                    return (
                        <tr className={`${className ? className : ""} markdown-tr`} {...props}>
                            {children}
                        </tr>
                    );
                },
                th: ({ className, children, ...props }) => {
                    return (
                        <th className={`${className ? className : ""} markdown-th`} {...props}>
                            {children}
                        </th>
                    );
                },
                td: ({ className, children, ...props }) => {
                    return (
                        <td className={`${className ? className : ""} markdown-td`} {...props}>
                            {children}
                        </td>
                    );
                },
                a: ({ className, children, ...props }) => {
                    return (
                        <a
                            className={`${className ? className : ""} markdown-a`}
                            href={props.href}
                            target={linkInNewTab ? "_blank" : "_self"}
                            rel="noreferrer noopener"
                        >
                            {children}
                        </a>
                    );
                },
            }}
        >
            {text}
        </Markdown>
    );
};

export default SolverMarkdown;
