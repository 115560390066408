export enum SettingsTab {
    SolverOptions = "solver-options",
    Execution = "execution",
}

export interface SettingsStateContextType {
    isOpen: boolean;
    activeTab: SettingsTab;
    setIsOpen: (open: boolean) => void;
    setActiveTab: (tab: SettingsTab) => void;
    openExecutionSettings: () => void;
}

import React, { createContext, useContext, useState } from "react";

const SettingsStateContext = createContext<SettingsStateContextType | undefined>(undefined);

export const SettingsStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(SettingsTab.SolverOptions);

    const openExecutionSettings = () => {
        setActiveTab(SettingsTab.Execution);
        setIsOpen(true);
    };

    return (
        <SettingsStateContext.Provider value={{ isOpen, activeTab, setIsOpen, setActiveTab, openExecutionSettings }}>
            {children}
        </SettingsStateContext.Provider>
    );
};

export const useSettingsState = () => {
    const context = useContext(SettingsStateContext);
    if (context === undefined) {
        throw new Error("useSettingsState must be used within a SettingsStateProvider");
    }
    return context;
};
