import React, { ReactNode, useEffect } from "react";
import { Button, Divider, notification, Popover, Select, Slider, Switch, Tabs, Tooltip, Typography } from "antd";
import { useSettingsState } from "../data/SettingsState";
import { GithubOutlined, GitlabOutlined } from "@ant-design/icons";

import { useSolverInterfaceContext } from "../data/SolverInterface";
import {
    BooleanInvocationOption,
    FloatInvocationOption,
    IntegerInvocationOption,
    InvocationOptionValue,
    SolverInvocationOption,
    StringInvocationOption,
    useSolverInvocationOptionsContext,
} from "../data/SolverInvocationOptions";
import { AuthType, SessionVisibility } from "../data/User";

import ExecutionEnvironmentForm from "./ExecutionEnvironmentForm";
import ExecutionImageForm from "./ExecutionImageForm";

import "./Settings.scss";

interface SettingsPopoverProps {
    children: ReactNode;
}

const SettingsPopover: React.FC<SettingsPopoverProps> = ({ children }) => {
    const { isOpen: popoverOpen, setIsOpen: onOpenChange, activeTab } = useSettingsState();
    const { appIsReady, logout, loggedIn, currentUser, activeRepo, updateUserDefaultSessionVisibility } =
        useSolverInterfaceContext();
    const invocationOptionsCtx = useSolverInvocationOptionsContext();
    const [api, contextHolder] = notification.useNotification();

    const updateInvocationOption = (name: string, value: InvocationOptionValue) =>
        invocationOptionsCtx.updateInvocationOption(name, value);

    useEffect(() => {
        if (!popoverOpen && appIsReady) {
            invocationOptionsCtx.saveInvocationOptions();
        }
    }, [popoverOpen]);

    const buildPopoverContent = () => {
        return (
            <div className="settings-container scrollbar scrollbar-gutter-stable-both">
                {buildCurrentUser()}
                {loggedIn && (
                    <>
                        <Divider type="horizontal" className="settings-divider" />
                        <Tabs
                            defaultActiveKey={activeTab}
                            items={[
                                {
                                    key: "solver-options",
                                    label: "Solver Options",
                                    children: <div className="settings-tab-content">{buildForm()}</div>,
                                },
                                {
                                    key: "execution",
                                    label: "Execution",
                                    children: (
                                        <div className="settings-tab-content">
                                            {activeRepo ? (
                                                <>
                                                    <ExecutionImageForm
                                                        org={activeRepo.org}
                                                        repo={activeRepo.name}
                                                        allowChanges={activeRepo.allow_modify_repo_settings}
                                                    />
                                                    <Divider type="horizontal" className="settings-divider" />
                                                    <ExecutionEnvironmentForm
                                                        org={activeRepo.org}
                                                        repo={activeRepo.name}
                                                        allowChanges={activeRepo.allow_modify_repo_settings}
                                                    />
                                                </>
                                            ) : (
                                                "Select a repo to configure execution settings"
                                            )}
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </>
                )}
                <Divider type="horizontal" className="settings-divider" />
                <Button type="primary" onClick={() => onOpenChange(false)}>
                    Close
                </Button>
            </div>
        );
    };

    const onLogout = () => {
        logout();

        onOpenChange(false);
    };

    const authTypeIcon = (authType: AuthType) => {
        switch (authType) {
            case AuthType.GitHub:
                return <GithubOutlined />;
            case AuthType.GitLab:
                return <GitlabOutlined />;
            default:
                return null;
        }
    };

    const updateVisibility = async (visibility: SessionVisibility) => {
        const success = await updateUserDefaultSessionVisibility(visibility);

        if (!success) {
            api.error({
                message: "Error updating visibility",
                description: "Failed to update default session visibility.",
            });
        }
    };

    const buildCurrentUser = () => {
        if (!currentUser) return null;

        return (
            <div className="settings-current-user">
                <div className="settings-current-user-row">
                    <div className="settings-current-user-info">
                        <Typography.Text strong>{currentUser.name}</Typography.Text>
                        {authTypeIcon(currentUser.auth_type)}
                    </div>
                    <Button onClick={onLogout}>Logout</Button>
                </div>
                <div className="settings-current-user-row">
                    <Typography.Text>Default Session Visibility</Typography.Text>
                    <Select
                        value={currentUser.default_session_visibility}
                        onChange={updateVisibility}
                        options={[
                            { value: SessionVisibility.PRIVATE, label: "Private" },
                            { value: SessionVisibility.PUBLIC_READ_ONLY, label: "Public (Read Only)" },
                            { value: SessionVisibility.PUBLIC_READ_WRITE, label: "Public (Read/Write)" },
                        ]}
                    />
                </div>
            </div>
        );
    };

    const buildForm = () => {
        if (!invocationOptionsCtx.invocationOptions || invocationOptionsCtx.invocationOptions.length === 0) {
            return <div className="settings-none">No settings available</div>;
        }

        return (
            <>
                {invocationOptionsCtx.invocationOptions.map((option, idx) => buildSettingsItem(option, idx))}
                <div className="settings-buttons">
                    <Button onClick={() => invocationOptionsCtx.resetInvocationOptions()}>Reset to defaults</Button>
                </div>
            </>
        );
    };

    const buildSettingsItem = (option: SolverInvocationOption, idx: number) => {
        const typedOption = option as
            | IntegerInvocationOption
            | FloatInvocationOption
            | BooleanInvocationOption
            | StringInvocationOption;

        return (
            <Tooltip title={typedOption.description} placement="left" key={idx}>
                <div className="settings-item">
                    <div className="settings-item-label">
                        <label>{typedOption.display_name}</label>
                        <label className="settings-item-default">
                            default: <span>{defaultText(typedOption)}</span>
                        </label>
                    </div>
                    <div className="settings-item-input">{buildFormItemInput(option)}</div>
                </div>
            </Tooltip>
        );
    };

    const buildFormItemInput = (option: SolverInvocationOption) => {
        switch (option.type) {
            case "int": {
                const integerOption = option as IntegerInvocationOption;

                // TODO: Would be nice to see more marks. Could use the greatest
                // non-self divisor of the range to get more marks.
                return (
                    <Slider
                        min={integerOption.min}
                        max={integerOption.max}
                        step={1}
                        onChange={(value) => updateInvocationOption(option.name, value)}
                        marks={{
                            [integerOption.min]: integerOption.min,
                            [integerOption.max]: integerOption.max,
                            [integerOption.default]: integerOption.default,
                        }}
                        value={integerOption.value ? integerOption.value : integerOption.default}
                    />
                );
            }
            case "float": {
                const floatOption = option as FloatInvocationOption;

                return (
                    <Slider
                        min={floatOption.min}
                        max={floatOption.max}
                        step={0.1}
                        onChange={(value) => updateInvocationOption(option.name, value)}
                        marks={{
                            [floatOption.min]: floatOption.min,
                            [floatOption.max]: floatOption.max,
                            [floatOption.default]: floatOption.default,
                        }}
                        value={floatOption.value ? floatOption.value : floatOption.default}
                    />
                );
            }
            case "bool": {
                const booleanOption = option as BooleanInvocationOption;
                return (
                    <Switch
                        checked={booleanOption.value !== null ? booleanOption.value : booleanOption.default}
                        onChange={(checked) => updateInvocationOption(option.name, checked)}
                    />
                );
            }
            case "string": {
                const stringOption = option as StringInvocationOption;

                const selectOptions = stringOption.valid_options.map((option) => {
                    const displayText: string | undefined = stringOption.display[option];

                    return { label: displayText ? displayText : option, value: option };
                });

                return (
                    <Select
                        defaultValue={stringOption.default}
                        value={stringOption.value ? stringOption.value : stringOption.default}
                        options={selectOptions}
                        onChange={(value) => updateInvocationOption(option.name, value)}
                    />
                );
            }
            default:
                console.error(`Unknown option type: ${option.type}`);
                return null;
        }
    };

    const defaultText = (
        typedOption: IntegerInvocationOption | FloatInvocationOption | BooleanInvocationOption | StringInvocationOption
    ) => {
        if (typedOption.type === "bool") {
            return `${typedOption.default ? "on" : "off"}`;
        } else if (typedOption.type === "string") {
            const defaultDisplayText: string | undefined = typedOption.display[typedOption.default];

            if (defaultDisplayText) {
                return defaultDisplayText;
            }
        }

        return `${typedOption.default}`;
    };

    return (
        <>
            {contextHolder}
            <Popover
                content={buildPopoverContent()}
                open={popoverOpen}
                trigger="click"
                arrow={false}
                onOpenChange={(open) => {
                    onOpenChange(open);
                }}
                placement="bottomRight"
            >
                {children}
            </Popover>
        </>
    );
};

export default SettingsPopover;
