"use client";
import React from "react";

import Convert from "ansi-to-html";
import { Typography } from "antd";

import "./Terminal.scss";

const convert = new Convert({ newline: true });

interface TerminalProps {
    command?: string;
    stdout?: string;
    stderr?: string;
    exitCode: number;
    completed: boolean;
    error_msg?: string;
}

const Terminal: React.FC<TerminalProps> = ({ command, stdout, stderr, exitCode, completed, error_msg }) => {
    const stdoutRef = React.useRef<HTMLPreElement>(null);
    const stderrRef = React.useRef<HTMLPreElement>(null);
    const [shouldAutoScrollStdout, setShouldAutoScrollStdout] = React.useState(true);
    const [shouldAutoScrollStderr, setShouldAutoScrollStderr] = React.useState(true);

    const isNearBottom = (element: HTMLElement) => {
        const threshold = 30; // pixels from bottom to consider "near bottom"
        return element.scrollHeight - element.scrollTop - element.clientHeight < threshold;
    };

    const handleScroll = (element: HTMLPreElement | null, setShouldAutoScroll: (value: boolean) => void) => {
        if (element) {
            setShouldAutoScroll(isNearBottom(element));
        }
    };

    React.useEffect(() => {
        if (stdoutRef.current && shouldAutoScrollStdout) {
            stdoutRef.current.scrollTop = stdoutRef.current.scrollHeight;
        }
    }, [stdout, shouldAutoScrollStdout]);

    React.useEffect(() => {
        if (stderrRef.current && shouldAutoScrollStderr) {
            stderrRef.current.scrollTop = stderrRef.current.scrollHeight;
        }
    }, [stderr, shouldAutoScrollStderr]);
    return (
        <div>
            {command && (
                <pre
                    ref={stdoutRef}
                    className="terminal"
                    onScroll={(e) => handleScroll(e.currentTarget as HTMLPreElement, setShouldAutoScrollStdout)}
                >
                    $ {command}
                    <br />
                    <span dangerouslySetInnerHTML={{ __html: stdout ? convert.toHtml(stdout) : "" }} />
                </pre>
            )}
            {stderr && (
                <div>
                    <Typography.Text type="danger" className="terminal-label">
                        Stderr:
                    </Typography.Text>
                    <pre
                        ref={stderrRef}
                        className="terminal danger"
                        onScroll={(e) => handleScroll(e.currentTarget as HTMLPreElement, setShouldAutoScrollStderr)}
                    >
                        <span dangerouslySetInnerHTML={{ __html: convert.toHtml(stderr) }} />
                    </pre>
                </div>
            )}
            {completed && (exitCode >= 0 || !error_msg) && (
                <Typography.Text type={exitCode === 0 ? "success" : "danger"} className="terminal-label">
                    Exit Code: {exitCode}
                </Typography.Text>
            )}
            {completed && error_msg && (
                <Typography.Text type="danger" className="terminal-label">
                    {error_msg}
                </Typography.Text>
            )}
        </div>
    );
};

export default Terminal;
