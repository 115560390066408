import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Layout, notification } from "antd";
import React, { useEffect, useState } from "react";

import { NavigationBehavior } from "../data/Navigation";
import "./SharedSider.scss";
import "./SessionsSider.scss";
import NewSessionButton from "./NewSessionButton";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import { deleteSession, Session, useLoadSession, useSession } from "../data/SolverSession";
import SessionsList from "./SessionsList";
import { getBranches } from "../data/Repos";

const { Sider } = Layout;

interface SessionsSiderProps {}

const SessionsSider: React.FC<SessionsSiderProps> = () => {
    const session = useSession();
    const loadSession = useLoadSession();

    const { currentUser, activeRepo, loadingRepos } = useSolverInterfaceContext();

    const [api, contextHolder] = notification.useNotification();
    const [collapsed, setCollapsed] = useState(false);
    const [branches, setBranches] = useState<string[]>([]);
    const [loadingBranches, setLoadingBranches] = useState<boolean>(false);

    useEffect(() => {
        if (activeRepo) {
            setLoadingBranches(true);
            getBranches(activeRepo.org, activeRepo.name, 250)
                .then((fetchedBranches) => {
                    setBranches(fetchedBranches);
                })
                .catch((error) => {
                    api.error({
                        message: "Failed to load branches",
                        description: error instanceof Error ? error.toString() : "Unknown error",
                        placement: "bottomRight",
                    });
                })
                .finally(() => {
                    setLoadingBranches(false);
                });
        }
    }, [activeRepo, api]);

    const buildSessionsList = () => {
        if (loadingRepos) {
            return undefined;
        } else if (!activeRepo) {
            return undefined;
        } else {
            return (
                <SessionsList
                    currentUser={currentUser}
                    repo={activeRepo}
                    activeSession={session}
                    hideNewSessionButton={true}
                    onNewSession={async (sessionInfo) => {
                        await loadSession(sessionInfo, NavigationBehavior.PUSH);
                    }}
                    onSwitchSession={(s: Session) => {
                        if (s && s.session_id !== session?.session_id) {
                            return loadSession(s.getInfo(), NavigationBehavior.PUSH);
                        } else {
                            return Promise.resolve();
                        }
                    }}
                    onDeleteSession={async (sessionToDelete: Session) => {
                        if (currentUser?.id !== sessionToDelete.user_id) return;

                        const deleted = await deleteSession(sessionToDelete.getInfo());

                        if (deleted && sessionToDelete.session_id === session?.session_id) {
                            await loadSession(undefined, NavigationBehavior.PUSH);
                        }

                        if (!deleted) {
                            api.error({
                                message: "Failed to delete Session",
                                placement: "bottomRight",
                            });
                            return;
                        }
                    }}
                    notification={api}
                    branches={branches}
                    isLoading={loadingBranches}
                />
            );
        }
    };

    const buildNewSessionButton = () => {
        if (!activeRepo || loadingRepos) {
            return null;
        }

        return (
            <NewSessionButton
                repoOrg={activeRepo.org}
                repoName={activeRepo.name}
                repo={activeRepo}
                onSessionCreated={async (sessionInfo) => {
                    await loadSession(sessionInfo, NavigationBehavior.PUSH);
                }}
                notification={api}
                branches={branches}
                isLoading={loadingBranches}
            />
        );
    };
    return (
        <>
            <div className="sessions-sider-container">
                <Sider
                    collapsible
                    collapsedWidth={40}
                    collapsed={collapsed}
                    trigger={
                        collapsed ? (
                            <RightOutlined className="sider-trigger-icon" />
                        ) : (
                            <LeftOutlined className="sider-trigger-icon" />
                        )
                    }
                    onCollapse={(value) => setCollapsed(value)}
                    width={310}
                    className={`sider ${collapsed ? "sider-collapsed" : "sider-expanded"}`}
                >
                    {!collapsed && buildSessionsList()}
                </Sider>
                <div
                    className={`new-session-button-container ${collapsed ? "collapsed" : ""}`}
                    onClick={(e) => e.stopPropagation()}
                >
                    {buildNewSessionButton()}
                </div>
            </div>
            {contextHolder}
        </>
    );
};

export default SessionsSider;
