import React from "react";

import { Button } from "antd";

import { PromptSuggestion } from "../data/Repos";

import "./PromptSuggestionChips.scss";

interface PromptSuggestionChipsProps {
    promptSuggestions: PromptSuggestion[];
    onClick: (suggestion: string) => void;
}

const PromptSuggestionChips: React.FC<PromptSuggestionChipsProps> = ({ promptSuggestions, onClick }) => {
    const buildSuggestion = (promptSuggestion: PromptSuggestion, index: number) => {
        return (
            <Button key={index} className="prompt-suggestion" onClick={() => onClick(promptSuggestion.full_prompt)}>
                <div className="prompt-suggestion-text">{promptSuggestion.summary}</div>
            </Button>
        );
    };

    if (promptSuggestions.length === 0) {
        return null;
    }

    return (
        <div className="prompt-suggestions-container">
            {promptSuggestions.map((promptSuggestion, index) => buildSuggestion(promptSuggestion, index))}
        </div>
    );
};

export default PromptSuggestionChips;
