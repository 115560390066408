"use client";
import React from "react";

import classNames from "classnames";
import { FileData } from "react-diff-view";

import { BisectContent, BisectRevisionState } from "../../data/TurnEventContent";
import ImmutableDiffCard from "../ImmutableDiffCard";
import SolverMarkdown from "../SolverMarkdown";

import { FileInfo, toFileInfos } from "../Utils";

import { buildCommitLabel } from "./Common";

import "./Bisect.scss";

interface BisectProps {
    bisectContent: BisectContent;
}

const Bisect: React.FC<BisectProps> = ({ bisectContent }) => {
    if (bisectContent.error_message) {
        return <SolverMarkdown text={`There was an error running \`bisect\`: ${bisectContent.error_message}`} />;
    }

    const buildBisectRevisionContent = (
        bisect_state: "started" | "running" | "finished",
        current_revision?: string,
        test_revisions?: { [revision: string]: { state: BisectRevisionState; message: string } }
    ) => {
        if (!test_revisions) {
            return null;
        }

        return (
            <table className="bisect-revisions-table">
                <tbody>
                    {Object.entries(test_revisions).map(([commit, { state: state, message }]) => {
                        if (bisect_state === "finished" && state === "pruned") {
                            return null;
                        }

                        const revisionClass = classNames({
                            "bisect-revision": true,
                            "bisect-revision-untested": state === "untested",
                            "bisect-revision-good": state === "good",
                            "bisect-revision-bad": state === "bad",
                            "bisect-revision-pruned": state === "pruned",
                            "bisect-revision-latest": commit === current_revision,
                        });
                        const revisionCommitClass = classNames({
                            "bisect-revision-commit": true,
                            "bisect-revision-commit-untested": state === "untested",
                            "bisect-revision-commit-good": state === "good",
                            "bisect-revision-commit-bad": state === "bad",
                            "bisect-revision-commit-pruned": state === "pruned",
                        });
                        const revisionStateClass = classNames({
                            "bisect-revision-state": true,
                            "bisect-revision-state-untested": state === "untested",
                            "bisect-revision-state-good": state === "good",
                            "bisect-revision-state-bad": state === "bad",
                            "bisect-revision-state-pruned": state === "pruned",
                        });
                        return (
                            <tr className={revisionClass} key={commit}>
                                <td className="bisect-revision-latest-marker">
                                    {commit === current_revision ? "*" : ""}
                                </td>
                                <td className={revisionStateClass}>{state}</td>
                                <td className={revisionCommitClass}>{commit}</td>
                                <td className="bisect-revision-message">{message}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    return (
        <div className="bisect-content">
            <SolverMarkdown
                text={`I'm running \`bisect\` to search for the commit that introduced the issue in \`${bisectContent.target}\`...`}
            />
            {bisectContent.test_revisions &&
                buildBisectRevisionContent(
                    bisectContent.state,
                    bisectContent.last_tested_rev,
                    bisectContent.test_revisions
                )}
            {bisectContent.state === "started" && <SolverMarkdown text="Starting to run `bisect` process..." />}
            {bisectContent.state === "running" && (
                <SolverMarkdown text={`Testing \`${bisectContent.target}\` on bisected revisions...`} />
            )}
            {bisectContent.state === "finished" && (
                <SolverMarkdown
                    text={`Finished running \`bisect\`. The breaking commit is \`${bisectContent.breaking_commit.commit}\`.`}
                />
            )}
            {bisectContent.breaking_commit && (
                <div className="bisect-breaking-commit">
                    {buildCommitLabel(
                        bisectContent.breaking_commit.author,
                        bisectContent.breaking_commit.date,
                        bisectContent.breaking_commit.message
                    )}
                    {toFileInfos([{ patch: bisectContent.breaking_commit.patch, change_ids: [] }], []).map(
                        (fileInfo: FileInfo) => {
                            const diffCardKey = (fileData: FileData) =>
                                `${fileData.oldRevision}-${fileData.newRevision}`;
                            return (
                                <ImmutableDiffCard
                                    key={diffCardKey(fileInfo.fileData)}
                                    fileInfo={fileInfo}
                                    expandCodeFn={undefined}
                                />
                            );
                        }
                    )}
                </div>
            )}
        </div>
    );
};

export default Bisect;
