"use client";
import { CloseOutlined, DeleteOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React, { forwardRef, MutableRefObject, useEffect, useLayoutEffect, useRef, useState } from "react";

import AvatarVariantFactory from "../data/AvatarVariantFactory";
import { useSolverInterfaceContext } from "../data/SolverInterface";
import { TurnEventType } from "../data/SolverInterfaceEvent";
import {
    AgentThoughtTurnEvent,
    BisectEvent,
    BlameEvent,
    CodeCoverageEvent,
    DocumentationEvent,
    EventChangeSetState,
    ExecutionEvent,
    LinterErrorsEvent,
    MergeUserBranchEvent,
    OpenFileEvent,
    ProfileEvent,
    ProjectTreeEvent,
    RelevantFilesEvent,
    RemoteCommitsEvent,
    sessionIsLoading,
    SessionStatus,
    SolutionReviewEvent,
    SolvingStoppedEvent,
    TextSearchEvent,
    Turn,
    TurnEvent,
    useAddConversationComment,
    useCanModifySession,
    useConversationComments,
    useEventChangeSets,
    useFetchChangeSet,
    useRemoveConversationComment,
    useRevertToTurn,
    useSession,
    useSessionStatus,
    useSolve,
    useTurns,
    WorkspaceCreationProgressEvent,
} from "../data/SolverSession";
import ImmutableDiffCard from "./ImmutableDiffCard";
import {
    DATA_ATTRIBUTE_CHANGES_END,
    DATA_ATTRIBUTE_CHANGES_START,
    DATA_ATTRIBUTE_EVENT_ID,
    DATA_ATTRIBUTE_EVENT_TYPE,
    MessageProps,
    MessageRefT,
} from "./Message";
import MessageGroup from "./MessageGroup";
import { MessageType } from "./MessageType";
import SolverMarkdown from "./SolverMarkdown";
import { Comment, FileInfo, getRelevantPath } from "./Utils";

import Bisect from "./messages/Bisect";
import Blame from "./messages/Blame";
import CodeCoverage from "./messages/CodeCoverage";
import Execution from "./messages/Execution";
import {
    OpenFileMessage,
    ProjectTreeMessage,
    SolvingStoppedMessage,
    TextSearchMessage,
} from "./messages/InlineMessage";
import LinterCard from "./messages/LinterCard";
import Profile from "./messages/Profile";
import RelevantFilesCard from "./messages/RelevantFilesCard";
import SolutionReview from "./messages/SolutionReview";
import WorkspaceProgress from "./messages/WorkspaceProgress";

import { ChangesContent, PlanEvent, WorkspaceCreationProgress } from "../data/TurnEventContent";
import solverAvatar from "../images/solver_icon_reverse_borderless.png";
import "./Conversation.scss";
import Plan from "./messages/Plan";
import RemoteCommits from "./messages/RemoteCommits";
import UserBranchMerge from "./messages/UserBranchMerge";

interface ConversationProps {
    restoreScrollPosition: () => void;
    onShowChangesView: () => void;
    onReportIssue: () => void;
    editingTurn: Turn | null;
    setEditingTurn: (turn: Turn | null) => void;
}

export type ContainerRefT = HTMLElement;

const canModifyTurn = (turn: Turn, turns: Turn[]): boolean => {
    if (!turn.allow_undo) {
        return false;
    }

    for (let i = turns.length - 1; i >= 0; i--) {
        const t: Turn = turns[i];
        if (!t.allow_undo) {
            return turn.idx > t.idx;
        }
    }
    return true;
};

const buildMessageButtons = (
    turn: Turn,
    turns: Turn[],
    editingTurn: Turn | null,
    startEditingTurn: (turn: Turn) => void,
    cancelEditingTurn: () => void,
    revertToTurn: (turnId: string | null) => void
) => {
    const canModify = canModifyTurn(turn, turns);
    return (
        <div className="message-buttons">
            <Tooltip title={!canModify ? "Cannot modify synced messages" : "Delete message"} placement="left">
                <button
                    className="delete-button"
                    onClick={() => {
                        const prevTurn = turns.find((t) => t.idx === turn.idx - 1);
                        revertToTurn(prevTurn?.id ?? null);
                    }}
                    disabled={!canModify}
                >
                    <DeleteOutlined />
                    <span>Delete</span>
                </button>
            </Tooltip>
            <Tooltip
                title={
                    !canModify
                        ? "Cannot modify synced messages"
                        : editingTurn === turn
                        ? "Cancel Edit"
                        : "Edit and Solve from this point"
                }
                placement="left"
            >
                <button
                    className={`edit-button ${editingTurn === turn ? "cancel-mode" : ""}`}
                    onClick={() => (editingTurn === turn ? cancelEditingTurn() : startEditingTurn(turn))}
                    disabled={!canModify}
                >
                    {editingTurn === turn ? <CloseOutlined /> : <EditOutlined />}
                    <span>{editingTurn === turn ? "Cancel Edit" : "Edit"}</span>
                </button>
            </Tooltip>
        </div>
    );
};

const Conversation = forwardRef<ContainerRefT, ConversationProps>(
    ({ restoreScrollPosition, onShowChangesView, onReportIssue, editingTurn, setEditingTurn }, containerRef) => {
        const turns = useTurns();
        const eventChangeSets = useEventChangeSets();
        const session = useSession();
        const sessionStatus = useSessionStatus();
        const canModifySession = useCanModifySession();
        const fetchChangeSet = useFetchChangeSet();
        const revertToTurn = useRevertToTurn();
        const solve = useSolve();

        const startEditingTurn = (turn: Turn) => setEditingTurn(turn);
        const cancelEditingTurn = () => setEditingTurn(null);
        const conversationComments = useConversationComments();
        const addConversationComment = useAddConversationComment();
        const removeConversationComment = useRemoveConversationComment();
        const { activeRepo } = useSolverInterfaceContext();

        const [userDidScroll, setUserDidScroll] = useState(false);
        const messageRefs = useRef<(MessageRefT | null)[][]>([]);

        const elementIsFullyScrolled = (element: HTMLElement) => {
            return Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) < 5;
        };

        const getContainerElement = (): HTMLElement | undefined => {
            const containerRefAsRef = containerRef as MutableRefObject<ContainerRefT | null>;
            if (!containerRefAsRef.current) return undefined;
            return containerRefAsRef.current;
        };

        const scrollToBottom = () => {
            const containerElement = getContainerElement();
            if (!containerElement) return;

            // If the user scrolled up, don't scroll to the bottom.
            if (userDidScroll) return;

            containerElement.scrollTop = containerElement.scrollHeight;
        };

        const handleSuggestionClick = (suggestion: string) => {
            if (!canModifySession()) return;
            solve(suggestion, []);
        };

        const handleIntersection = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) return;

                const eventId = entry.target.getAttribute(DATA_ATTRIBUTE_EVENT_ID);
                const eventType = entry.target.getAttribute(DATA_ATTRIBUTE_EVENT_TYPE);
                const eventStart = entry.target.getAttribute(DATA_ATTRIBUTE_CHANGES_START);
                const eventEnd = entry.target.getAttribute(DATA_ATTRIBUTE_CHANGES_END);

                if (
                    eventId &&
                    eventType &&
                    eventStart &&
                    eventEnd &&
                    (eventType === TurnEventType.EDIT ||
                        eventType === TurnEventType.REVERT ||
                        eventType === TurnEventType.TURN_CHANGES ||
                        eventType === TurnEventType.EXECUTION_EDIT)
                ) {
                    fetchChangeSet(
                        eventId,
                        eventType as
                            | TurnEventType.EDIT
                            | TurnEventType.REVERT
                            | TurnEventType.TURN_CHANGES
                            | TurnEventType.EXECUTION_EDIT,
                        eventStart,
                        eventEnd
                    );
                    observer.unobserve(entry.target);
                }
            });
        };

        useLayoutEffect(() => {
            restoreScrollPosition();
        }, []);

        useEffect(() => {
            const containerElement = getContainerElement();
            if (!containerElement) return;

            const onScroll = () => {
                setTimeout(() => {
                    setUserDidScroll(!elementIsFullyScrolled(containerElement));
                }, 100);
            };

            containerElement.addEventListener("scroll", onScroll);

            return () => containerElement.removeEventListener("scroll", onScroll);
        }, []);

        useEffect(() => {
            if (sessionStatus === SessionStatus.SOLVING) {
                scrollToBottom();
            }
        }, [sessionStatus, turns, eventChangeSets, userDidScroll]);

        useEffect(() => {
            const containerElement = getContainerElement();
            if (!containerElement) return;

            const observer = new IntersectionObserver(handleIntersection, {
                root: containerElement,
                rootMargin: "0px",
                threshold: 0.1,
            });

            const eventElements = document.querySelectorAll("[data-event-id]");
            eventElements.forEach((element) => {
                observer.observe(element);
            });

            return () => observer.disconnect();
        }, []);

        const turnThread = (turn: Turn) => {
            return (
                <React.Fragment key={turn.id}>
                    {promptMessage(turn)}
                    {eventMessages(turn)}
                </React.Fragment>
            );
        };

        const eventMessages = (turn: Turn) => {
            const events = turn.events.filter((turnEvent) => !shouldExcludeMessage(turnEvent));

            const messageProps: MessageProps[] = events.map((turnEvent: TurnEvent) => {
                let changesContent: ChangesContent | undefined = undefined;
                if (
                    turnEvent.event_type === TurnEventType.EDIT ||
                    turnEvent.event_type === TurnEventType.REVERT ||
                    turnEvent.event_type === TurnEventType.TURN_CHANGES ||
                    turnEvent.event_type === TurnEventType.EXECUTION_EDIT
                ) {
                    changesContent = turnEvent.content as ChangesContent;
                }

                return {
                    key: turnEvent.id,
                    renderContent: () => eventMessageContent(turnEvent, turn),
                    messageType: MessageType.AGENT,
                    eventId: turnEvent.id,
                    eventType: turnEvent.event_type,
                    changesContent,
                    ...eventMessageCollapseProps(turnEvent),
                };
            });

            const messageGroupProps = {
                messages: messageProps,
                messageType: MessageType.AGENT,
                collapsible: turn.idx < turns.length - 1 || !sessionIsLoading(sessionStatus),
                collapsed: turn.idx < turns.length - 1,
                avatar: solverAvatar,
                isGrayedOut: !!editingTurn && turn.idx >= editingTurn.idx,
            };

            return (
                <MessageGroup
                    {...messageGroupProps}
                    ref={(msg) => {
                        if (!msg) return;
                        messageRefs.current[turn.idx] = msg;
                    }}
                />
            );
        };

        const shouldExcludeMessage = (turnEvent: TurnEvent): boolean => {
            if (turnEvent.event_type === TurnEventType.SOLVER_LOG) return true;
            if (!turnEvent.content) return true;

            if (turnEvent.event_type === TurnEventType.AGENT_THOUGHT) {
                const agentThought = turnEvent as AgentThoughtTurnEvent;
                return !agentThought.content.message || agentThought.content.message === "";
            } else if (turnEvent.event_type === TurnEventType.WORKSPACE_CREATION_PROGRESS) {
                const workspaceCreationProgress = (turnEvent as WorkspaceCreationProgressEvent).content.status;
                return workspaceCreationProgress === WorkspaceCreationProgress.DONE;
            } else if (turnEvent.event_type === TurnEventType.SOLVING_STOPPED) {
                const solvingStopped = turnEvent as SolvingStoppedEvent;
                return !solvingStopped.content.solving_error || solvingStopped.content.solving_error === "";
            }

            return false;
        };

        const promptMessage = (turn: Turn) => {
            if (!session) return undefined;
            if (turn.nl_text === "") return undefined;

            scrollToBottom();

            const avatarUrl = AvatarVariantFactory.createURLVariant(session.user_avatar_url, session.auth_type, 48);
            return (
                <MessageGroup
                    messages={[
                        {
                            renderContent: () => (
                                <div className="user-message-content">
                                    <SolverMarkdown text={turn.nl_text} />
                                    {canModifySession() &&
                                        buildMessageButtons(
                                            turn,
                                            turns,
                                            editingTurn,
                                            startEditingTurn,
                                            cancelEditingTurn,
                                            revertToTurn
                                        )}
                                </div>
                            ),
                            messageType: MessageType.USER,
                            collapsible: true,
                            collapsedThresholdPx: 400,
                            defaultExpanded: false,
                            key: turn.id,
                        },
                    ]}
                    messageType={MessageType.USER}
                    collapsible={false}
                    collapsed={false}
                    avatar={avatarUrl}
                    isGrayedOut={!!editingTurn && turn.idx >= editingTurn.idx}
                />
            );
        };

        const eventMessageCollapseProps = (turnEvent: TurnEvent) => {
            if (turnEvent.event_type === TurnEventType.RELEVANT_FILES) {
                return {
                    collapsible: true,
                    defaultExpanded: false,
                };
            } else if (turnEvent.event_type === TurnEventType.LINT_ERRORS) {
                return {
                    collapsible: false,
                };
            } else if (turnEvent.event_type === TurnEventType.SOLUTION_REVIEW) {
                return {
                    collapsible: false,
                };
            } else if (turnEvent.event_type === TurnEventType.REMOTE_COMMITS) {
                return {
                    collapsible: false,
                };
            } else if (turnEvent.event_type === TurnEventType.MERGE_USER_BRANCH) {
                return {
                    collapsible: false,
                };
            } else if (
                turnEvent.event_type === TurnEventType.EDIT ||
                turnEvent.event_type === TurnEventType.TURN_CHANGES ||
                turnEvent.event_type === TurnEventType.REVERT ||
                turnEvent.event_type === TurnEventType.EXECUTION_EDIT
            ) {
                return {
                    collapsible: true,
                    defaultExpanded: false,
                    collapsedThresholdPx: 400,
                };
            }

            return {
                collapsible: true,
                defaultExpanded: true,
            };
        };

        const eventMessageContent = (turnEvent: TurnEvent, turn: Turn) => {
            switch (turnEvent.event_type) {
                case TurnEventType.AGENT_THOUGHT:
                    return <SolverMarkdown text={(turnEvent as AgentThoughtTurnEvent).content.message} />;
                case TurnEventType.OPEN_FILE:
                    return <OpenFileMessage openFileContent={(turnEvent as OpenFileEvent).content} />;
                case TurnEventType.TEXT_SEARCH:
                    return <TextSearchMessage textSearchContent={(turnEvent as TextSearchEvent).content} />;
                case TurnEventType.REMOTE_COMMITS:
                    if (!activeRepo) {
                        return null;
                    }

                    return (
                        <RemoteCommits
                            content={(turnEvent as RemoteCommitsEvent).content}
                            fullRepoName={activeRepo.full_name}
                        />
                    );
                case TurnEventType.MERGE_USER_BRANCH:
                    if (!session) {
                        return null;
                    }

                    return (
                        <UserBranchMerge
                            content={(turnEvent as MergeUserBranchEvent).content}
                            userBranchName={session.branch_name}
                        />
                    );
                case TurnEventType.PROJECT_TREE:
                    return <ProjectTreeMessage projectTreeContent={(turnEvent as ProjectTreeEvent).content} />;
                case TurnEventType.DOCUMENTATION:
                    return <SolverMarkdown text={(turnEvent as DocumentationEvent).content.summary} />;
                case TurnEventType.CODE_COVERAGE:
                    return <CodeCoverage codeCoverageContent={(turnEvent as CodeCoverageEvent).content} />;
                case TurnEventType.EXECUTION:
                    return <Execution executionContent={(turnEvent as ExecutionEvent).content} />;
                case TurnEventType.EDIT:
                case TurnEventType.REVERT:
                case TurnEventType.EXECUTION_EDIT: {
                    const editChangeSet = eventChangeSets.get(turnEvent.id);

                    if (!editChangeSet || editChangeSet.state === EventChangeSetState.LOADING) {
                        return (
                            <div className="message-loading">
                                <LoadingOutlined className="message-loading-icon" />
                            </div>
                        );
                    } else if (editChangeSet.state === EventChangeSetState.ERROR) {
                        const changesContent = turnEvent.content as ChangesContent;
                        const eventType = turnEvent.event_type as TurnEventType.EDIT | TurnEventType.REVERT;

                        return (
                            <div className="info-message">
                                <strong>Failed to load message</strong>
                                <Button
                                    className="info-cta"
                                    onClick={() =>
                                        fetchChangeSet(
                                            turnEvent.id,
                                            eventType,
                                            changesContent.start,
                                            changesContent.end
                                        )
                                    }
                                >
                                    Retry
                                </Button>
                            </div>
                        );
                    }

                    return editChangeSet.changeSet.file_infos.map((fileInfo: FileInfo) => {
                        const fileData = fileInfo.fileData;
                        const diffCardKey = `${fileData.oldRevision}-${fileData.newRevision}`;
                        return (
                            <ImmutableDiffCard
                                key={diffCardKey}
                                fileInfo={fileInfo}
                                onSubmitComment={
                                    turn.idx === turns.length - 1
                                        ? (comment: Comment) => {
                                              addConversationComment(comment);
                                          }
                                        : undefined
                                }
                                onRetractComment={(comment) => removeConversationComment(comment)}
                                submittedComments={conversationComments.filter(
                                    (comment) => getRelevantPath(comment.fileData) === getRelevantPath(fileData)
                                )}
                            />
                        );
                    });
                }
                case TurnEventType.PLAN: {
                    const planEvent = turnEvent as unknown as PlanEvent;
                    return <Plan planContent={planEvent.content} />;
                }
                case TurnEventType.PROFILE:
                    return <Profile profileContent={(turnEvent as ProfileEvent).content} />;
                case TurnEventType.SOLUTION_REVIEW:
                    return (
                        <SolutionReview
                            solutionReview={(turnEvent as SolutionReviewEvent).content}
                            onSuggestionClick={handleSuggestionClick}
                        />
                    );
                case TurnEventType.RELEVANT_FILES: {
                    return <RelevantFilesCard relevantFilesContent={(turnEvent as RelevantFilesEvent).content} />;
                }
                case TurnEventType.LINT_ERRORS:
                    return <LinterCard content={(turnEvent as LinterErrorsEvent).content} />;
                case TurnEventType.SUBMIT:
                    return renderInfoMessage("Solver finished", turn.idx === turns.length - 1);
                case TurnEventType.RESOURCES_EXHAUSTED:
                    return renderInfoMessage("Solver paused", turn.idx === turns.length - 1);
                case TurnEventType.BLAME:
                    if (!activeRepo) {
                        return null;
                    }

                    return (
                        <Blame blameContent={(turnEvent as BlameEvent).content} fullRepoName={activeRepo.full_name} />
                    );
                case TurnEventType.BISECT:
                    return <Bisect bisectContent={(turnEvent as BisectEvent).content} />;
                case TurnEventType.WORKSPACE_CREATION_PROGRESS:
                    return (
                        <WorkspaceProgress
                            workspaceCreationProgressContent={(turnEvent as WorkspaceCreationProgressEvent).content}
                        />
                    );
                case TurnEventType.SOLVING_STOPPED:
                    return (
                        <SolvingStoppedMessage
                            solvingStoppedContent={(turnEvent as SolvingStoppedEvent).content}
                            onReportIssue={onReportIssue}
                        />
                    );
                default:
                    return <SolverMarkdown text={`\`\`\`\n${JSON.stringify(turnEvent.content, null, 2)}\n\`\`\``} />;
            }
        };

        if (!activeRepo) {
            return null;
        }

        const renderButtonContainer = (isLastTurn: boolean) =>
            isLastTurn ? (
                <div className="info-cta-container">
                    <Button className="info-cta" onClick={onShowChangesView}>
                        View repository changes
                    </Button>
                    <Button
                        className="info-cta"
                        onClick={() => solve("You're on the right track; please continue", [])}
                    >
                        Continue Solving
                    </Button>
                </div>
            ) : null;

        const renderInfoMessage = (message: string, isLastTurn: boolean) => (
            <div className="info-message">
                <strong>{message}</strong>
                {renderButtonContainer(isLastTurn)}
            </div>
        );

        return <>{turns.map((turn: Turn) => turnThread(turn))}</>;
    }
);

Conversation.displayName = "Conversation";

export default Conversation;
