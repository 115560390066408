import React from "react";

import {
    CloudUploadOutlined,
    CopyOutlined,
    DownloadOutlined,
    FlagOutlined,
    LoadingOutlined,
    MergeOutlined,
    PullRequestOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Tooltip, Typography } from "antd";
import type { ModalStaticFunctions } from "antd/es/modal/confirm";
import { NotificationInstance } from "antd/lib/notification/interface";
import type { MenuInfo } from "rc-menu/lib/interface";

import { isAxiosError } from "axios";
import ReactMarkdown from "react-markdown";

import { solverInterfaceApiAxios } from "../data/SolverInterfaceConstants";
import {
    getPatchContents,
    getSessionPullRequest,
    GetSessionPullRequestResponse,
    mergeSessionBaseBranch,
    MergeSessionBaseBranchResponse,
    pushSessionToRemote,
    PushToRemoteResultCode,
    Session,
    SessionInfo,
} from "../data/SolverSession";
import { AuthType, authTypeDisplayName, branchUrl } from "../data/User";

import "./ActiveSession.scss";

interface ActiveSessionControlsProps {
    session: Session;
    className: string;
    exportDisabled: boolean;
    modificationDisabled: boolean;
    onReportIssue: () => void;
    onPulledRemoteChanges: () => void;
    notification: NotificationInstance;
    modal: Omit<ModalStaticFunctions, "warn">;
}

const ActiveSessionControls: React.FC<ActiveSessionControlsProps> = ({
    session,
    className,
    exportDisabled,
    modificationDisabled,
    onReportIssue,
    onPulledRemoteChanges,
    notification,
    modal,
}) => {
    const buildFindOrCreatePRButton = () => {
        if (session.auth_type !== AuthType.GitHub) return null;
        if (!session.remote_branch_name) return null;

        return (
            <Tooltip
                title={`Find or create pull request using ${session.remote_branch_name} on ${authTypeDisplayName(
                    session.auth_type
                )}`}
                arrow={false}
            >
                <Button
                    className="active-session-controls-button"
                    icon={<PullRequestOutlined />}
                    disabled={exportDisabled || modificationDisabled}
                    onClick={() => {
                        findOrCreatePullRequest(session, notification, modal);
                    }}
                >
                    PR
                </Button>
            </Tooltip>
        );
    };

    const buildPushToRemoteButton = () => {
        if (session.auth_type !== AuthType.GitHub) return null;

        return (
            <Tooltip
                title={`Create a branch with these changes on ${authTypeDisplayName(session.auth_type)}`}
                arrow={false}
            >
                <Button
                    className="active-session-controls-button"
                    icon={<CloudUploadOutlined />}
                    disabled={exportDisabled || modificationDisabled}
                    onClick={() => pushToRemote(session, notification, modal)}
                >
                    Push to {authTypeDisplayName(session.auth_type)}
                </Button>
            </Tooltip>
        );
    };

    const buildSyncButton = () => {
        if (session.auth_type !== AuthType.GitHub) return null;
        if (!session.remote_branch_name) return null;

        return (
            <Tooltip
                title={`Push changes from Solver to ${
                    session.remote_branch_name
                } and pull changes from ${authTypeDisplayName(session.auth_type)}`}
                arrow={false}
            >
                <Button
                    className="active-session-controls-button"
                    icon={<SyncOutlined />}
                    disabled={exportDisabled || modificationDisabled}
                    onClick={() => syncWithRemote(session, notification, modal, onPulledRemoteChanges)}
                >
                    Sync
                </Button>
            </Tooltip>
        );
    };

    const buildMergeBaseBranchButton = () => {
        if (session.auth_type !== AuthType.GitHub) return null;

        return (
            <Tooltip title={`Merge upstream changes from ${session.branch_name} into this Session`} arrow={false}>
                <Button
                    className="active-session-controls-button"
                    icon={<MergeOutlined />}
                    disabled={exportDisabled || modificationDisabled}
                    onClick={() => {
                        mergeBaseBranch(session, notification, modal, onPulledRemoteChanges);
                    }}
                >
                    Merge Base Branch
                </Button>
            </Tooltip>
        );
    };

    const buildPatchDropdown = () => {
        return (
            <Dropdown
                arrow={false}
                disabled={exportDisabled}
                menu={{
                    items: [
                        { icon: <DownloadOutlined />, label: "Download patch file", key: "file" },
                        { icon: <CopyOutlined />, label: "Copy patch to clipboard", key: "clipboard" },
                    ],
                    onClick: (info: MenuInfo) => {
                        if (!session) return;

                        switch (info.key) {
                            case "file":
                                exportToFile(session.getInfo(), notification);
                                break;
                            case "clipboard":
                                exportToClipboard(session.getInfo(), notification, modal);
                                break;
                        }
                    },
                }}
                placement="bottomLeft"
            >
                <Button
                    disabled={exportDisabled}
                    className="active-session-controls-button active-session-controls-button-small"
                    icon={<DownloadOutlined />}
                />
            </Dropdown>
        );
    };

    const buildReportIssueButton = () => {
        return (
            <Tooltip title="Report an issue" arrow={false}>
                <Button
                    className="active-session-controls-button active-session-controls-button-small"
                    icon={<FlagOutlined />}
                    onClick={onReportIssue}
                />
            </Tooltip>
        );
    };

    return (
        <>
            <div className={className}>
                {session.remote_branch_name && buildFindOrCreatePRButton()}
                {session.remote_branch_name ? buildSyncButton() : buildPushToRemoteButton()}
                {buildMergeBaseBranchButton()}
                {buildPatchDropdown()}
                {buildReportIssueButton()}
            </div>
        </>
    );
};

const findOrCreatePullRequest = async (
    session: Session,
    notification: NotificationInstance,
    modal: Omit<ModalStaticFunctions, "warn">
) => {
    const findingPullRequest = modal.info({
        title: `Looking for existing pull request on ${authTypeDisplayName(session.auth_type)}...`,
        content: (
            <div className="push-to-remote-modal-loading-icon">
                <LoadingOutlined />
            </div>
        ),
        okButtonProps: { style: { display: "none" } },
        maskClosable: true,
        onCancel: () => {
            findingPullRequest.destroy();
        },
    });

    const response: GetSessionPullRequestResponse = await getSessionPullRequest(session.getInfo());

    findingPullRequest.destroy();

    if (response.error) {
        notification.error({
            message: "Failed to find pull request",
            description: response.error,
            placement: "bottomRight",
        });
        return;
    }

    const getPRModal = modal.success({
        content: (
            <div>
                <a
                    className="push-to-remote-modal-link"
                    href={response.pr_url}
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    {response.pr_already_exists
                        ? "Click here to view pull request"
                        : "Click here to create pull request"}
                </a>
            </div>
        ),
        closable: true,
        okButtonProps: { style: { display: "none" } },
        maskClosable: false,
        onCancel: () => {
            getPRModal.destroy();
        },
    });
};

const pushToRemote = async (
    session: Session,
    notification: NotificationInstance,
    modal: Omit<ModalStaticFunctions, "warn">
) => {
    const pushingToRemoteModal = modal.info({
        title: `Pushing changes to ${authTypeDisplayName(session.auth_type)}...`,
        content: (
            <div className="push-to-remote-modal-loading-icon">
                <LoadingOutlined />
            </div>
        ),
        okButtonProps: { style: { display: "none" } },
        maskClosable: true,
        onCancel: () => {
            pushingToRemoteModal.destroy();
        },
    });

    try {
        const pushToRemoteResponse = await pushSessionToRemote(session.getInfo());

        switch (pushToRemoteResponse.result_code) {
            case PushToRemoteResultCode.NO_CONTENT:
                popPushToRemoteNoContent(notification);
                break;
            case PushToRemoteResultCode.SUCCESS:
                const pushedToRemoteModal = modal.success({
                    title: (
                        <div>
                            Pushed new branch{" "}
                            <a
                                className="push-to-remote-modal-link"
                                href={branchUrl(
                                    session.auth_type,
                                    session.repo_name,
                                    pushToRemoteResponse.remote_branch_name
                                )}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {pushToRemoteResponse.remote_branch_name}
                            </a>{" "}
                            to {authTypeDisplayName(session.auth_type)}
                        </div>
                    ),
                    content: (
                        <a
                            className="push-to-remote-modal-link"
                            href={pushToRemoteResponse.pull_request}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            Click here to open a PR on {authTypeDisplayName(session.auth_type)}
                        </a>
                    ),
                    closable: true,
                    okButtonProps: { style: { display: "none" } },
                    maskClosable: false,
                    onCancel: () => {
                        pushedToRemoteModal.destroy();
                    },
                });
                break;
            case PushToRemoteResultCode.PUSH_FAILED:
            default:
                popPushToRemoteError(session.session_id, notification, pushToRemoteResponse.error_message);
        }
    } catch (error) {
        const errorString: string | undefined = isAxiosError(error) ? error.response?.data?.message : undefined;

        popPushToRemoteError(session.session_id, notification, errorString);
    } finally {
        pushingToRemoteModal.destroy();
    }
};

const popPushToRemoteNoContent = (notification: NotificationInstance) => {
    notification.info({
        message: "No changes to submit",
        description: "No changes to submit in this session.",
        placement: "bottomRight",
    });
};

const popPushToRemoteError = (session_id: string, notification: NotificationInstance, errorMessage?: string) => {
    notification.error({
        message: "Pushing to remote failed",
        description: errorMessage || "Could not open pull request.",
        placement: "bottomRight",
    });
};

const syncWithRemote = (
    session: Session,
    notification: NotificationInstance,
    modal: Omit<ModalStaticFunctions, "warn">,
    onPulledRemoteChanges: () => void
) => {
    const syncingModal = modal.info({
        title: `Syncing with branch on ${authTypeDisplayName(session.auth_type)}...`,
        content: (
            <div className="push-to-remote-modal-loading-icon">
                <LoadingOutlined />
            </div>
        ),
        okButtonProps: { style: { display: "none" } },
        maskClosable: true,
        onCancel: () => {
            syncingModal.destroy();
        },
    });

    solverInterfaceApiAxios
        .post(`/sessions/${session.getInfo().org}/${session.getInfo().repo}/${session.getInfo().session_id}/sync`)
        .then((response) => {
            syncingModal.destroy();
            const { pulled_updates, pushed_updates } = response.data;

            if (!pulled_updates && !pushed_updates) {
                notification.success({
                    message: "No changes",
                    description: "Everything up to date",
                    placement: "bottomRight",
                });
            } else {
                let statusMessage;
                if (pulled_updates) {
                    statusMessage = "Successfully pulled changes from remote";
                } else {
                    statusMessage = "Successfully pushed changes to remote";
                }

                notification.success({
                    message: `Changes synced with ${session.remote_branch_name}`,
                    description: statusMessage,
                    placement: "bottomRight",
                });

                if (pulled_updates) {
                    onPulledRemoteChanges();
                }
            }
        })
        .catch((error) => {
            syncingModal.destroy();
            notification.error({
                message: "Failed to sync changes",
                description: error.response?.data?.error || "Unknown error occurred",
                placement: "bottomRight",
            });
        });
};

const mergeBaseBranch = async (
    session: Session,
    notification: NotificationInstance,
    modal: Omit<ModalStaticFunctions, "warn">,
    onPulledRemoteChanges: () => void
) => {
    const mergingBaseBranchModal = modal.info({
        title: `Merging changes from ${session.branch_name}...`,
        content: (
            <div className="push-to-remote-modal-loading-icon">
                <LoadingOutlined />
            </div>
        ),
        okButtonProps: { style: { display: "none" } },
        maskClosable: true,
        onCancel: () => {
            mergingBaseBranchModal.destroy();
        },
    });

    const response: MergeSessionBaseBranchResponse = await mergeSessionBaseBranch(session.getInfo());

    mergingBaseBranchModal.destroy();

    if (response.error) {
        notification.error({
            message: response.error,
            placement: "bottomRight",
        });
        return;
    } else if (response.did_merge_changes) {
        notification.success({
            message: "Merged changes",
            description: `Merged changes from ${session.branch_name} into this session.`,
            placement: "bottomRight",
        });

        onPulledRemoteChanges();
    } else {
        notification.info({
            message: "No changes to merge",
            description: `No changes to merge from ${session.branch_name}.`,
            placement: "bottomRight",
        });
    }
};

const COPY_PATCH_INSTRUCTIONS_MARKDOWN = `
To apply the patch, from the root directory of the repository, run:

### Mac
\`\`\`
pbpaste | patch -p1
\`\`\`

### Linux
\`\`\`
xclip -o -selection clipboard | patch -p1
\`\`\`

### Windows (Git Bash)
\`\`\`
cat /dev/clipboard | patch -p1
\`\`\`

### Windows (WSL)
\`\`\`
powershell.exe Get-Clipboard | patch -p1
\`\`\`
`;

const exportToClipboard = async (
    sessionInfo: SessionInfo,
    notification: NotificationInstance,
    modal: Omit<ModalStaticFunctions, "warn">
) => {
    try {
        const patch = await getPatchContents(sessionInfo);
        const copyPatchModal = modal.info({
            title: null,
            content: (
                <div>
                    <div className="copy-patch-modal-copy">
                        <Typography.Text>Click to copy changes to clipboard</Typography.Text>
                        <Button
                            icon={<CopyOutlined />}
                            onClick={async () => {
                                try {
                                    await navigator.clipboard.writeText(patch);
                                    popDidCopyPatch(notification);
                                } catch {
                                    popCopyFailed(notification);
                                }
                                copyPatchModal.destroy();
                            }}
                        >
                            Copy
                        </Button>
                    </div>
                    <div className="copy-patch-modal-instructions">
                        <ReactMarkdown className="copy-patch-modal-instructions-pre">
                            {COPY_PATCH_INSTRUCTIONS_MARKDOWN}
                        </ReactMarkdown>
                    </div>
                </div>
            ),
            width: 600,
            closable: true,
            maskClosable: true,
            okButtonProps: { style: { display: "none" } },
        });
    } catch {
        popFailedToFetchPatch(sessionInfo.session_id, notification);
    }
};

const exportToFile = async (sessionInfo: SessionInfo, notification: NotificationInstance) => {
    try {
        const patch = await getPatchContents(sessionInfo);
        const url = window.URL.createObjectURL(new Blob([patch]));
        const link = document.createElement("a");
        link.href = url;
        link.download = `session-${sessionInfo.session_id}.patch`;
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        popDidDownloadPatch(notification);
    } catch (error: unknown) {
        if (error instanceof Error) console.error(error.message);

        popFailedToFetchPatch(sessionInfo.session_id, notification);
    }
};

const popDidDownloadPatch = (notification: NotificationInstance) => {
    notification.success({
        message: "Export complete",
        description: "Downloaded changes.",
        placement: "bottomRight",
        duration: 5,
    });
};

const popDidCopyPatch = (notification: NotificationInstance) => {
    notification.success({
        message: "Export complete",
        description: "Copied changes to clipboard.",
        placement: "bottomRight",
        duration: 5,
    });
};

const popFailedToFetchPatch = (session_id: string, notification: NotificationInstance) => {
    notification.error({
        message: "Export failed",
        description: "Failed to fetch session changes.",
        placement: "bottomRight",
    });
};

const popCopyFailed = (notification: NotificationInstance) => {
    notification.error({
        message: "Copy failed",
        description: "Could not copy changes to clipboard.",
        placement: "bottomRight",
        duration: 5,
    });
};

export default ActiveSessionControls;
