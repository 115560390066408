"use client";
import React from "react";

import { CodeCoverageContent } from "../../data/TurnEventContent";

import Terminal from "./Terminal";

import "./CodeCoverage.scss";

interface CodeCoverageProps {
    codeCoverageContent: CodeCoverageContent;
}

const CodeCoverage: React.FC<CodeCoverageProps> = ({ codeCoverageContent }) => {
    const computeBucket = (coverage: number) => {
        if (coverage >= 85) {
            return "great";
        } else if (coverage >= 70) {
            return "good";
        } else if (coverage >= 55) {
            return "ok";
        } else if (coverage >= 35) {
            return "bad";
        } else if (coverage >= 15) {
            return "terrible";
        } else {
            return "zero";
        }
    };

    if (codeCoverageContent.error_code) {
        return (
            <Terminal
                command={codeCoverageContent.command}
                stdout={codeCoverageContent.message}
                stderr={codeCoverageContent.error_output}
                exitCode={1}
                completed={true}
            />
        );
    } else {
        return (
            <div>
                <Terminal
                    command={codeCoverageContent.command}
                    stdout={codeCoverageContent.message}
                    stderr={undefined}
                    exitCode={0}
                    completed={true}
                />
                <table className="coverage-table">
                    <tbody>
                        {codeCoverageContent.definitions.map((definition) => {
                            return (
                                <tr key={definition.definition} className="coverage-row">
                                    <td className="coverage-arrow">&#x21B3;</td>
                                    <td className="coverage-definition">{definition.definition}</td>
                                    <td className="coverage-coverage">{definition.coverage.toPrecision(3)}%</td>
                                    <td className="coverage-bar">
                                        <div
                                            className={`coverage-bar-inner ${computeBucket(definition.coverage)}`}
                                            style={{ width: `${definition.coverage}%` }}
                                            data-coverage={definition.coverage.toPrecision(3)}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
};

export default CodeCoverage;
