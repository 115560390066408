"use client";
import { Card, Col, List, Row, Tag, Typography } from "antd";
import React from "react";

import { RelevantFilesContent, RelevantFile } from "../../data/TurnEventContent";

import "./RelevantFilesCard.scss";

const fileExtensionColorMap: { [key: string]: string } = {
    ".ts": "cyan",
    ".tsx": "cyan",
    ".js": "gold",
    ".jsx": "purple",
    ".py": "green",
    ".java": "red",
    ".cpp": "magenta",
    ".html": "volcano",
    ".css": "geekblue",
    ".json": "lime",
    ".md": "orange",
    ".txt": "default",
};

function getFileExtension(filePath: string): string | null {
    const pathSegments = filePath.split("/");
    const fileName = pathSegments.pop() || "";
    const ext = fileName.includes(".") ? fileName.split(".").pop() : "";
    if (!ext) return null;
    return ext.length > 8 ? `${ext.substring(0, 8)}...` : `.${ext}`;
}

function getFileExtensionAsTag(filePath: string) {
    const ext = getFileExtension(filePath);
    const color = fileExtensionColorMap[ext ?? ""] || "default";
    return (
        <Tag color={color} className="relevant-files-extension-tag">
            {ext ?? "?"}
        </Tag>
    );
}

const CollapsedRelevanceRow: React.FC<{ file: RelevantFile }> = ({ file }) => {
    return (
        <Row>
            <Col span={1} className="relevant-files-row-extension">
                {getFileExtensionAsTag(file.path)}
            </Col>
            <Col span={12} className="relevant-files-row-path">
                <Typography.Text className="diff-header-path">&lrm;{file.path}</Typography.Text>
            </Col>
        </Row>
    );
};

interface RelevantFilesCardProps {
    relevantFilesContent: RelevantFilesContent;
}

const RelevantFilesCard: React.FC<RelevantFilesCardProps> = ({ relevantFilesContent }) => {
    if (relevantFilesContent.pending_message) {
        return <Typography.Text type="secondary"> {relevantFilesContent.pending_message} </Typography.Text>;
    }

    const files = relevantFilesContent.files;

    if (files.length === 0) {
        return <Typography.Text type="secondary">No relevant files found</Typography.Text>;
    }

    const filesSuffix = files.length === 1 ? "" : "s";

    return (
        <>
            <Typography.Text type="secondary" className="relevant-files-summary">
                {`Found ${files.length} relevant file${filesSuffix}`}
            </Typography.Text>
            <Card>
                <List
                    size="small"
                    dataSource={files.sort((a, b) => b.score - a.score)}
                    renderItem={(item) => <CollapsedRelevanceRow file={item} />}
                />
            </Card>
        </>
    );
};

export default RelevantFilesCard;
