import React from "react";

import { WarningOutlined, FileTextOutlined, FolderOutlined, SearchOutlined, FlagOutlined } from "@ant-design/icons";

import SolverMarkdown from "../SolverMarkdown";

import {
    OpenFileContent,
    ProjectTreeContent,
    SolvingStoppedContent,
    TextSearchContent,
} from "../../data/TurnEventContent";

import "./InlineMessage.scss";
import { Button, Tooltip } from "antd";

interface InlineMessageProps {
    icon: React.ReactNode;
    text: string;
}

const InlineMessage: React.FC<InlineMessageProps> = ({ icon, text }) => (
    <div className="inline-message">
        <span className="inline-message-icon">{icon}</span>
        <span className="inline-message-text">
            <SolverMarkdown text={text} />
        </span>
    </div>
);

const OpenFileMessage: React.FC<{ openFileContent: OpenFileContent }> = ({ openFileContent: { path, status } }) => {
    const text = () => {
        switch (status) {
            case "success":
                return `Reading \`${path}\``;
            case "file_not_found":
            case "directory":
            case "definition_not_found":
                return `Not found: \`${path}\``;
        }
    };

    return <InlineMessage icon={<FileTextOutlined />} text={text()} />;
};

const TextSearchMessage: React.FC<{ textSearchContent: TextSearchContent }> = ({
    textSearchContent: { pattern, path },
}) => {
    const text = () => {
        if (pattern !== null) {
            if (path === null) {
                return `Searching repo for \`${pattern}\``;
            } else {
                return `Searching for \`${pattern}\``;
            }
        }

        return "Searching repo";
    };

    return <InlineMessage icon={<SearchOutlined />} text={text()} />;
};

const ProjectTreeMessage: React.FC<{ projectTreeContent: ProjectTreeContent }> = ({ projectTreeContent }) => {
    const message =
        projectTreeContent.path === "."
            ? "Viewing project structure"
            : `Viewing project structure at \`${projectTreeContent.path}\``;

    return <InlineMessage icon={<FolderOutlined />} text={message} />;
};

const SolvingStoppedMessage: React.FC<{ solvingStoppedContent: SolvingStoppedContent; onReportIssue: () => void }> = ({
    solvingStoppedContent,
    onReportIssue,
}) => {
    const message = `${solvingStoppedContent.solving_error}`;

    return (
        <div className="solving-stopped-message-container">
            <InlineMessage icon={<WarningOutlined />} text={message} />
            <Button className="info-cta" icon={<FlagOutlined />} onClick={onReportIssue}>
                Report an issue
            </Button>
        </div>
    );
};

export { InlineMessage, OpenFileMessage, ProjectTreeMessage, SolvingStoppedMessage, TextSearchMessage };
