"use client";
import { Collapse, CollapseProps, Typography } from "antd";
import React from "react";
import { FileData } from "react-diff-view";

import { BlameContent } from "../../data/TurnEventContent";
import ImmutableDiffCard from "../ImmutableDiffCard";
import SolverMarkdown from "../SolverMarkdown";

import { FileInfo, toFileInfos } from "../Utils";

import { buildCommitLabel, buildGithubLinkButton } from "./Common";

import "./Blame.scss";

interface BlameProps {
    blameContent: BlameContent;
    fullRepoName: string;
}

const Blame: React.FC<BlameProps> = ({ blameContent, fullRepoName }) => {
    const buildBlameCommitContent = (repoName: string, content: BlameContent): CollapseProps["items"] => {
        const lineRange =
            content.line_start && content.line_end ? { start: content.line_start, end: content.line_end } : undefined;
        return content.commits.map((commit, idx) => {
            return {
                key: idx,
                label: buildCommitLabel(commit.author, commit.date, commit.message),
                extra: buildGithubLinkButton(repoName, commit.commit),
                children: toFileInfos([{ patch: commit.patch, change_ids: [] }], []).map((fileInfo: FileInfo) => {
                    const diffCardKey = (fileData: FileData) => `${fileData.oldRevision}-${fileData.newRevision}`;
                    return (
                        <ImmutableDiffCard
                            key={diffCardKey(fileInfo.fileData)}
                            highlightRange={
                                fileInfo.fileData.newPath === content.path || fileInfo.fileData.oldPath === content.path
                                    ? lineRange
                                    : undefined
                            }
                            fileInfo={fileInfo}
                            expandCodeFn={() => {}}
                        />
                    );
                }),
            };
        });
    };

    if (blameContent.error_type) {
        return (
            <div>
                <SolverMarkdown text={blameContent.message} />
                <Typography.Text type="danger">{blameContent.error}</Typography.Text>
            </div>
        );
    } else {
        return (
            <div>
                <SolverMarkdown text={blameContent.message} />
                <div onClick={(e) => e.stopPropagation()}>
                    <Collapse
                        className="blame-event-collapse"
                        items={buildBlameCommitContent(fullRepoName, blameContent)}
                        defaultActiveKey={[]}
                    />
                </div>
            </div>
        );
    }
};

export default Blame;
