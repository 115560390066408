"use client";
import React from "react";
import DOMPurify from "dompurify";
import { Typography } from "antd";

import { ProfileContent } from "../../data/TurnEventContent";
import Terminal from "./Terminal";
import "./Profile.scss";

interface ProfileProps {
    profileContent: ProfileContent;
}

export const Profile: React.FC<ProfileProps> = ({ profileContent }) => {
    const handleSvgRef = (node: HTMLDivElement | null) => {
        if (node) {
            const svgElement = node.querySelector("svg");
            if (svgElement) {
                svgElement.style.width = "100%";
                svgElement.style.height = "auto";
                svgElement.style.maxHeight = "none"; // Ensure no max height constraint
            }
        }
    };

    if (profileContent.svg) {
        const sanitizedSvg = DOMPurify.sanitize(profileContent.svg);
        return (
            <div className="profile-svg-wrapper">
                <div ref={handleSvgRef} dangerouslySetInnerHTML={{ __html: sanitizedSvg }} className="profile-svg" />
            </div>
        );
    }
    if (profileContent.error) {
        return (
            <Terminal
                command={undefined}
                stdout={undefined}
                stderr={profileContent.error}
                exitCode={1}
                completed={true}
            />
        );
    }

    return <Typography.Text type="warning">No profile data available.</Typography.Text>;
};

export default Profile;
