import { GithubOutlined, GitlabOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Image, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { SolverInterfaceStatus, useSolverInterfaceContext } from "../data/SolverInterface";
import { AuthType } from "../data/User";

import solverIconReverse from "../images/solver_icon_reverse_transparent.png";
import "../Startup.scss";

const Startup: React.FC = () => {
    const { solverInterfaceStatus, login, signUpToken } = useSolverInterfaceContext();
    const [showWelcome, setShowWelcome] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setShowWelcome(true), 500);
        return () => clearTimeout(timer);
    }, []); // Empty dependency array to ensure it only runs once on mount

    const handleLogin = (authType: AuthType) => {
        setIsLoggingIn(true);
        login(authType, signUpToken);
    };

    const startupElement = () => {
        switch (solverInterfaceStatus) {
            case SolverInterfaceStatus.ERROR:
                return <span className="startup-text">Failed to connect to Solver</span>;
            case SolverInterfaceStatus.FAILED_TO_LOAD_REPOS:
                return <span className="startup-text">Failed to load repos</span>;
            case SolverInterfaceStatus.SIGN_UP_TOKEN_INVALID:
                return (
                    <span className="startup-text">
                        Sign up link invalid or expired. Please contact us for a new sign up link.
                    </span>
                );
            case SolverInterfaceStatus.LOGGED_OUT:
                return (
                    <div className={`startup-login-container ${isLoggingIn ? "startup-fade-out" : ""}`}>
                        <div className={`startup-welcome ${showWelcome ? "startup-fade-in" : ""}`}>
                            <Image src={solverIconReverse} preview={false} width={100} height={100} alt="Solver icon" />
                            <h1 className="startup-welcome-text startup-welcome-header">Welcome to Solver</h1>
                        </div>
                        {signUpToken && (
                            <div className={`startup-sign-up-banner ${showWelcome ? "startup-fade-in" : ""}`}>
                                <p className="startup-welcome-text startup-welcome-subheader">
                                    Sign in to complete onboarding and start Solving.
                                </p>
                            </div>
                        )}
                        <div className={`startup-button-container ${showWelcome ? "startup-fade-in" : ""}`}>
                            <Button className="startup-button" block onClick={() => handleLogin(AuthType.GitHub)}>
                                <GithubOutlined />
                                Log in with GitHub
                            </Button>
                        </div>
                        <div className={`startup-button-container ${showWelcome ? "startup-fade-in" : ""}`}>
                            <Tooltip placement="bottom" title="Coming Soon">
                                <Button
                                    disabled={true}
                                    className="startup-button"
                                    block
                                    onClick={() => handleLogin(AuthType.GitLab)}
                                >
                                    <GitlabOutlined />
                                    Log in with GitLab
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                );
            case SolverInterfaceStatus.LOADING:
                return <LoadingOutlined style={{ fontSize: "40px" }} />;
            case SolverInterfaceStatus.USER_NOT_ALLOWLISTED:
                return (
                    <div className="startup-text">
                        Thank you for your interest in using Solver! We're working on rolling out to more organizations
                        and individuals. Please visit{" "}
                        <a className="startup-link" href="https://solverai.com/#early-access" target="_blank">
                            https://solverai.com/#early-access
                        </a>{" "}
                        to request access.
                    </div>
                );
            default:
                return null;
        }
    };

    return <div className="startup">{startupElement()}</div>;
};

export default Startup;
