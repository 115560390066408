import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";
import React, { useEffect, useState } from "react";
import "./NewRepoModal.scss";
import { AuthType, vcsProviderRepoConfigUrl } from "../data/User";

interface NewRepoModalProps {
    open: boolean;
    onClose: () => void;
    authType: AuthType;
}

const NewRepoModal: React.FC<NewRepoModalProps> = ({ open, onClose, authType }) => {
    const [hasClickedCreate, setHasClickedCreate] = useState(false);

    // Reset state when modal closes
    useEffect(() => {
        if (!open) {
            setHasClickedCreate(false);
        }
    }, [open]);

    const handleCreateRepo = (e: React.MouseEvent) => {
        e.stopPropagation();
        setHasClickedCreate(true);
    };
    return (
        <Modal
            title={hasClickedCreate ? "One More Step" : "Connect with GitHub"}
            open={open}
            onCancel={onClose}
            footer={null}
            width={700}
            bodyStyle={{ padding: 0 }}
            className="repo-modal"
        >
            <div className="modal-content">
                {!hasClickedCreate ? (
                    <div className="options-container">
                        <div className="option-box">
                            <Typography.Title level={4}>Have a Repository?</Typography.Title>
                            <Typography.Text className="option-box-description">
                                Connect an existing GitHub repository with Solver
                            </Typography.Text>
                            <Button
                                type="primary"
                                href={vcsProviderRepoConfigUrl(authType)}
                                target="_blank"
                                rel="noreferrer"
                                icon={<PlusCircleOutlined />}
                                className="option-box-button"
                            >
                                Connect with Repository
                            </Button>
                        </div>

                        <div className="divider" />

                        <div className="option-box">
                            <Typography.Title level={4}>Starting Fresh?</Typography.Title>
                            <Typography.Text className="option-box-description">
                                Create a new GitHub repository to use with Solver
                            </Typography.Text>
                            <div>
                                <Button
                                    href="https://github.com/new"
                                    target="_blank"
                                    rel="noreferrer"
                                    icon={<PlusCircleOutlined />}
                                    onClick={handleCreateRepo}
                                    className="option-box-button"
                                >
                                    Create New Repository
                                </Button>
                                <Typography.Text className="new-tab-hint">
                                    Opens in a new tab — keep this window open
                                </Typography.Text>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="post-create-container">
                        <Typography.Title level={4}>Great! Now let's connect Solver</Typography.Title>
                        <Typography.Text className="post-create-description">
                            Once you've created your repository, click below to connect it with Solver:
                        </Typography.Text>
                        <Button
                            type="primary"
                            size="large"
                            href={vcsProviderRepoConfigUrl(authType)}
                            target="_blank"
                            rel="noreferrer"
                            icon={<PlusCircleOutlined />}
                            className="post-create-button"
                        >
                            Connect Repository with Solver
                        </Button>
                        <Typography.Text className="post-create-hint">
                            After connecting, you'll be able to see your repository in the dropdown above
                        </Typography.Text>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default NewRepoModal;
