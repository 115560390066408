import { theme } from "antd";

const { darkAlgorithm } = theme;

import styleConstants from "./constants.module.scss";

export const APP_THEME = {
    algorithm: darkAlgorithm,
    token: {
        colorPrimary: styleConstants.SOLVER_BLUE,
        colorPrimaryBorderHover: styleConstants.SOLVER_BLUE_HOVER,
    },
};
