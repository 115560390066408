import { Tour, TourProps } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import "./TourManager.scss";

interface TourManagerProps {
    loggedIn: boolean;
    faqClosed: boolean;
}

const TOUR_IDS = {
    taskInput: "tour-task-input",
    solveButton: "tour-solve-button",
    createSession: "tour-create-session",
    repoSelect: "tour-repo-select",
    settingsButton: "tour-settings-button",
    faqButton: "tour-faq-button",
} as const;

const TOUR_STEPS: TourProps["steps"] = [
    {
        title: "Choose the Repository",
        target: () => document.getElementById(TOUR_IDS.repoSelect)!,
        placement: "bottom",
        description: "Select a repository to work with. We've provided demo repos, or you can add your own.",
    },
    {
        title: "Describe Your Task",
        target: () => document.getElementById(TOUR_IDS.taskInput)!,
        placement: "top",
        description: "Any task you'd normally work on is fair game!",
    },
    {
        title: "Solve",
        target: () => document.getElementById(TOUR_IDS.solveButton)!,
        placement: "top",
        description: "Let Solver start working on your task.",
    },
    {
        title: "Choose a Different Branch",
        target: () => document.getElementById(TOUR_IDS.createSession)!,
        placement: "left",
        description: "Start a new session from a different branch.",
    },
    {
        title: "Configure Code Execution",
        target: () => document.getElementById(TOUR_IDS.settingsButton)!,
        placement: "right",
        description: "Solver can run code and tests. You can set up a Docker image in the settings.",
    },
    {
        title: "Frequently Asked Questions",
        target: () => document.getElementById(TOUR_IDS.faqButton)!,
        placement: "bottom",
        description: "View FAQs to learn more about Solver's features.",
    },
];

const TourManager: React.FC<TourManagerProps> = ({ loggedIn, faqClosed }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);

    const checkElementsReady = useCallback(
        () =>
            Object.values(TOUR_IDS).every((id) => {
                const tourElement = document.getElementById(id);
                return tourElement !== null;
            }),
        []
    );

    // Initialize tour when conditions are met
    useEffect(() => {
        if (!loggedIn || !faqClosed || localStorage.getItem("shownTour")) {
            return;
        }

        const checkInterval = setInterval(() => {
            if (checkElementsReady()) {
                setIsOpen(true);
                localStorage.setItem("shownTour", "true");
                clearInterval(checkInterval);
            }
        }, 100);

        // Clear interval after 5 seconds
        const timeout = setTimeout(() => {
            clearInterval(checkInterval);
            if (!checkElementsReady()) {
                console.warn("Tour initialization timed out");
            }
        }, 5000);

        return () => {
            clearInterval(checkInterval);
            clearTimeout(timeout);
        };
    }, [loggedIn, faqClosed, checkElementsReady]);

    // Handle tour close events (only from X button or clicking outside)
    const handleClose = useCallback(() => {
        const activeElement = document.activeElement;
        // Don't close if a tour navigation button was clicked
        if (!activeElement?.classList.contains("ant-tour-buttons")) {
            setIsOpen(false);
            setCurrentStep(0);
        }
    }, []);
    // Handle clicks outside the tour
    useEffect(() => {
        if (!isOpen) return;

        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (!target.closest(".ant-tour")) {
                handleClose();
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => document.removeEventListener("click", handleClickOutside);
    }, [isOpen, handleClose]);

    if (!checkElementsReady()) return null;

    return (
        <Tour open={isOpen} onClose={handleClose} current={currentStep} onChange={setCurrentStep} steps={TOUR_STEPS} />
    );
};

export default React.memo(TourManager);
