import React from "react";
import { AudioRecorder } from "react-audio-voice-recorder";
import "./CustomAudioRecorder.scss";

interface CustomAudioRecorderProps {
    onRecordingComplete: (blob: Blob) => void;
    disabled: boolean;
}

export const CustomAudioRecorder: React.FC<CustomAudioRecorderProps> = ({ onRecordingComplete, disabled }) => {
    return (
        <div className={`custom-audio-recorder ${disabled ? "disabled" : ""}`} onClick={(e) => e.stopPropagation()}>
            {/* HACK: We swap the handlers for the submit and discard buttons to work around positioning limitations
                in react-audio-voice-recorder. The "discard" button (left) actually submits, while the
                "submit" button (right) actually discards. */}
            {!disabled && (
                <AudioRecorder
                    onRecordingComplete={onRecordingComplete}
                    audioTrackConstraints={{
                        noiseSuppression: true,
                        echoCancellation: true,
                    }}
                    downloadOnSavePress={false}
                    downloadFileExtension="wav"
                    showVisualizer={true}
                    classes={{
                        AudioRecorderClass: "audio-recorder",
                        AudioRecorderStartSaveClass: "audio-recorder-start-save custom-submit-button",
                        AudioRecorderPauseResumeClass: "display-none",
                        AudioRecorderDiscardClass: "audio-recorder-discard",
                    }}
                />
            )}
        </div>
    );
};

export default CustomAudioRecorder;
