"use client";
import React, { useEffect, useState } from "react";

import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Tooltip, Typography } from "antd";

import { DockerExecutionSettings, ExecutionImageType, useExecutionSettingsContext } from "../data/ExecutionSettings";

import "./Settings.scss";

interface ExecutionImageFormProps {
    org: string;
    repo: string;
    allowChanges: boolean;
}

interface ExecutionImageFormValues {
    docker_registry_url: string;
    docker_image: string;
    docker_tag: string;
    repo_mount_point?: string;
    docker_username: string;
    docker_password: string;
    shell_executable?: string;
}

const formValuesFromExecutionSettings = (settings: DockerExecutionSettings): ExecutionImageFormValues => {
    return {
        docker_registry_url: settings.docker_registry_url || "",
        docker_image: settings.docker_image || "",
        docker_tag: settings.docker_tag || "",
        repo_mount_point: settings.repo_mount_point || "",
        docker_username: settings.docker_username || "",
        docker_password: settings.docker_password || "",
        shell_executable: settings.shell_executable || "",
    };
};

const ExecutionImageForm: React.FC<ExecutionImageFormProps> = ({ org, repo, allowChanges }) => {
    const {
        currentExecutionImage,
        updateCustomExecutionImage,
        updateDefaultExecutionImage,
        updatingImage,
        resetExecutionImage,
        loadingImage,
        imageError,
        defaultExecutionImages,
        loadingDefaultExecutionImages,
        defaultExecutionImagesError,
    } = useExecutionSettingsContext();

    const [configuringCustomImage, setConfiguringCustomImage] = useState<boolean>(false);

    const [editingCustomImage, setEditingCustomImage] = useState<boolean>(false);
    const [resetingImage, setResetingImage] = useState<boolean>(false);

    const [form] = Form.useForm<ExecutionImageFormValues>();

    const settingsAreNew = currentExecutionImage.type === ExecutionImageType.None;

    const onFinishEditing = async () => {
        if (currentExecutionImage.type !== ExecutionImageType.Custom) {
            return;
        }

        try {
            await form.validateFields();
        } catch {
            // validateFields raises an exception if there are validation errors.
            return;
        }

        const existingValues = currentExecutionImage.settings as { [key: string]: string };

        const newValues: { [key: string]: string } = [
            "docker_image",
            "docker_tag",
            "repo_mount_point",
            "shell_executable",
        ].reduce(
            (acc, key) => ({
                ...acc,
                ...(existingValues[key] !== form.getFieldValue(key) && { [key]: form.getFieldValue(key) }),
            }),
            {}
        );

        if (Object.keys(newValues).length === 0) {
            setEditingCustomImage(false);
            return;
        }

        await updateCustomExecutionImage(newValues);

        setEditingCustomImage(false);
    };

    const resetImage = async () => {
        setResetingImage(true);
        await resetExecutionImage();
        setResetingImage(false);
        setEditingCustomImage(false);
        setConfiguringCustomImage(false);
        form.resetFields();
    };

    useEffect(() => {
        if (currentExecutionImage.type === ExecutionImageType.Custom) {
            setConfiguringCustomImage(true);
        }
    }, [currentExecutionImage.type]);

    useEffect(() => {
        if (currentExecutionImage.type === ExecutionImageType.Custom) {
            const formValues = formValuesFromExecutionSettings(currentExecutionImage.settings);
            form.setFieldsValue(formValues);
        } else {
            form.setFieldsValue(form.getFieldsValue());
        }
    }, [currentExecutionImage, form]);

    const onFinishForm = (values: ExecutionImageFormValues) => {
        updateCustomExecutionImage({
            docker_registry_url: values.docker_registry_url,
            docker_image: values.docker_image,
            docker_tag: values.docker_tag,
            repo_mount_point: values.repo_mount_point,
            docker_username: values.docker_username,
            docker_password: values.docker_password,
            shell_executable: values.shell_executable,
        });
    };

    const selectOptions = () => {
        const options = defaultExecutionImages.map((image) => ({
            label: image.title,
            value: JSON.stringify(image),
        }));

        if (currentExecutionImage.type !== ExecutionImageType.Default) {
            options.push({ label: "Configure a custom image", value: "custom" });
        }

        return options;
    };

    const buildDefaultList = () => {
        if (defaultExecutionImagesError) {
            return <Typography.Text type="danger">{defaultExecutionImagesError}</Typography.Text>;
        } else if (loadingDefaultExecutionImages) {
            return (
                <div className="execution-settings-loading">
                    Loading preconfigured images
                    <LoadingOutlined />
                </div>
            );
        }

        return (
            <div className="execution-default-image-container">
                <label className="execution-image-select-label">Images</label>
                <Select
                    className="execution-image-select"
                    style={{ width: "100%" }}
                    placeholder="Select an image"
                    loading={loadingDefaultExecutionImages}
                    disabled={!allowChanges || updatingImage}
                    onChange={(value) => {
                        if (value === "custom") {
                            setConfiguringCustomImage(true);
                        } else {
                            updateDefaultExecutionImage(JSON.parse(value));
                        }
                    }}
                    options={selectOptions()}
                    value={
                        currentExecutionImage.type === ExecutionImageType.Default
                            ? currentExecutionImage.settings.title
                            : undefined
                    }
                />
            </div>
        );
    };

    const buildForm = () => {
        return (
            <div className="execution-custom-image-form-container">
                {configuringCustomImage && currentExecutionImage.type !== ExecutionImageType.Custom && (
                    <Button
                        className="execution-custom-image-back-button"
                        onClick={() => setConfiguringCustomImage(false)}
                        disabled={!allowChanges || updatingImage}
                    >
                        Back to default images
                    </Button>
                )}
                <label className="execution-image-select-label">
                    Custom image
                    {!settingsAreNew && (
                        <Tooltip title="Edit custom image settings" arrow={false}>
                            <Button
                                className="execution-image-custom-edit-button"
                                icon={<EditOutlined />}
                                disabled={!allowChanges || editingCustomImage}
                                size="small"
                                onClick={() => setEditingCustomImage(true)}
                            />
                        </Tooltip>
                    )}
                </label>
                <Form
                    disabled={loadingImage || !allowChanges}
                    form={form}
                    labelCol={{
                        span: 8,
                    }}
                    labelAlign="left"
                    colon={false}
                    requiredMark={false}
                    onFinish={onFinishForm}
                    autoComplete="off"
                >
                    {buildFormItem(
                        "docker_registry_url",
                        "Registry URL",
                        false,
                        false,
                        "The registry where the image is hosted. Example: docker.io. Can be left blank if the image is hosted on Docker Hub."
                    )}
                    {buildFormItem(
                        "docker_image",
                        "Image",
                        false,
                        settingsAreNew || editingCustomImage,
                        "The name of the image to use for execution."
                    )}
                    {buildFormItem(
                        "docker_tag",
                        "Tag",
                        false,
                        false,
                        "A tag of the image. If not provided, the latest tag will be used."
                    )}
                    {buildFormItem(
                        "repo_mount_point",
                        "Repo Mount Point",
                        false,
                        false,
                        "The path to the repo within the docker image. If not provided, /repo will be used."
                    )}
                    {buildFormItem(
                        "shell_executable",
                        "Shell Executable",
                        false,
                        false,
                        "The shell executable to use for running commands. If not provided, '/bin/bash' will be used."
                    )}
                    {settingsAreNew &&
                        buildFormItem("docker_username", "Username", false, settingsAreNew || editingCustomImage)}
                    {settingsAreNew &&
                        buildFormItem(
                            "docker_password",
                            "Access Token",
                            true,
                            settingsAreNew || editingCustomImage,
                            "The access token to use for authentication. Consult the host's documentation for information on how to generate an access token."
                        )}
                    {editingCustomImage && (
                        <Form.Item style={{ textAlign: "center", marginTop: 24 }}>
                            <Button type="primary" loading={updatingImage} block onClick={onFinishEditing}>
                                Update Custom Image
                            </Button>
                        </Form.Item>
                    )}
                    {settingsAreNew && (
                        <Form.Item style={{ textAlign: "center", marginTop: 24 }}>
                            <Button type="primary" htmlType="submit" disabled={updatingImage} block>
                                Set Custom Image
                            </Button>
                        </Form.Item>
                    )}
                </Form>
            </div>
        );
    };

    const buildFormItem = (key: string, label: string, password: boolean, required: boolean, tooltip?: string) => {
        const inputDisabled =
            !allowChanges ||
            updatingImage ||
            (!settingsAreNew && !editingCustomImage) ||
            (editingCustomImage && key === "docker_registry_url");

        return (
            <Form.Item
                className="execution-image-form-item"
                label={label}
                name={key}
                tooltip={tooltip}
                rules={[
                    {
                        required,
                        message: `${label} is required`,
                    },
                ]}
            >
                {password ? <Input.Password disabled={inputDisabled} /> : <Input disabled={inputDisabled} />}
            </Form.Item>
        );
    };

    if (loadingImage) {
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <LoadingOutlined />
            </div>
        );
    }

    return (
        <>
            <h3 className="settings-title">Image</h3>
            <p className="settings-description">
                Configure a Docker image to run and test your code. Choose a preconfigured default or provide a custom
                image to meet your project's specific requirements and dependencies.
            </p>
            {!allowChanges && (
                <p className="settings-instructions">
                    The Docker image is locked for {org}/{repo}. Contact the repository owner to make changes.
                </p>
            )}
            {imageError && <Typography.Text type="danger">{imageError}</Typography.Text>}
            {updatingImage && (
                <div className="execution-settings-loading">
                    Updating image
                    <LoadingOutlined />
                </div>
            )}
            {currentExecutionImage.type !== ExecutionImageType.Custom && !configuringCustomImage && buildDefaultList()}
            {currentExecutionImage.type !== ExecutionImageType.Default && configuringCustomImage && buildForm()}
            {currentExecutionImage.type !== ExecutionImageType.None && allowChanges && (
                <Tooltip title="">
                    <Button className="execution-image-reset-button" onClick={resetImage} loading={resetingImage}>
                        Reset image
                    </Button>
                </Tooltip>
            )}
        </>
    );
};

export default ExecutionImageForm;
